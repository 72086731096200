<template>
  <div class="gameAgents" :class="{lan_En : lan != 'zh'}">
    <div class="bg1"><img src="../../assets/home/cont4_bg1.jpg" alt=""></div>
    <div class="bg2"><img src="../../assets/home/cont4_bg2.jpg" alt=""></div>
    <div class="content1">
      <div class="cont_title gradient_text">{{ $t('gameAgents.title') }}</div>
      <div class="cont_desc">{{ $t('gameAgents.text') }}</div>
    </div>
    <div class="content2">
      <p class="cont2_text1">{{ $t('gameAgents.title1') }}</p>
      <div class="showCont">
        <img src="../../assets/help/game_img1.png" alt="">
        <img src="../../assets/help/game_img2.png" alt="">
      </div>
      <div class="cont_table">
        <table class="table_cont">
          <tr class="theader">
            <th style="width: 50px;">{{ $t('gameAgents.table.th.text1') }}</th>
            <th class="color1">{{ $t('gameAgents.table.th.text2') }}</th>
            <th class="color2">{{ $t('gameAgents.table.th.text3') }}</th>
            <th class="color2">{{ $t('gameAgents.table.th.text4') }}</th>
          </tr>
          <tr>
            <td>1</td>
            <td class="color1">{{ $t('gameAgents.table.td1.text1') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td1.text2') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td1.text3') }}</td>
          </tr>
          <tr>
            <td>2</td>
            <td class="color1">{{ $t('gameAgents.table.td2.text1') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td2.text2') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td2.text3') }}</td>
          </tr>
          <tr>
            <td>3</td>
            <td class="color1">{{ $t('gameAgents.table.td3.text1') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td3.text2') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td3.text3') }}</td>
          </tr>
          <tr>
            <td>4</td>
            <td class="color1">{{ $t('gameAgents.table.td4.text1') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td4.text2') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td4.text3') }}</td>
          </tr>
          <tr>
            <td>5</td>
            <td class="color1">{{ $t('gameAgents.table.td5.text1') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td5.text2') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td5.text3') }}</td>
          </tr>
          <tr>
            <td>6</td>
            <td class="color1">{{ $t('gameAgents.table.td6.text1') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td6.text2') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td6.text3') }}</td>
          </tr>
          <tr>
            <td>7</td>
            <td class="color1">{{ $t('gameAgents.table.td7.text1') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td7.text2') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td7.text3') }}</td>
          </tr>
          <tr>
            <td>8</td>
            <td class="color1">{{ $t('gameAgents.table.td8.text1') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td8.text2') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td8.text3') }}</td>
          </tr>
          <tr>
            <td>9</td>
            <td class="color1">{{ $t('gameAgents.table.td9.text1') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td9.text2') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td9.text3') }}</td>
          </tr>
          <tr>
            <td>10</td>
            <td class="color1">{{ $t('gameAgents.table.td10.text1') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td10.text2') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td10.text3') }}</td>
          </tr>
          <tr>
            <td>11</td>
            <td class="color1">{{ $t('gameAgents.table.td11.text1') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td11.text2') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td11.text3') }}</td>
          </tr>
          <tr>
            <td>12</td>
            <td class="color1">{{ $t('gameAgents.table.td12.text1') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td12.text2') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td12.text3') }}</td>
          </tr>
          <tr>
            <td>13</td>
            <td class="color1">{{ $t('gameAgents.table.td13.text1') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td13.text2') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td13.text3') }}</td>
          </tr>
          <tr>
            <td>14</td>
            <td class="color1">{{ $t('gameAgents.table.td14.text1') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td14.text2') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td14.text3') }}</td>
          </tr>
          <tr>
            <td>15</td>
            <td class="color1">{{ $t('gameAgents.table.td15.text1') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td15.text2') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td15.text3') }}</td>
          </tr>
          <tr>
            <td>16</td>
            <td class="color1">{{ $t('gameAgents.table.td16.text1') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td16.text2') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td16.text3') }}</td>
          </tr>
          <tr>
            <td>17</td>
            <td class="color1">{{ $t('gameAgents.table.td17.text1') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td17.text2') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td17.text3') }}</td>
          </tr>
          <tr>
            <td>18</td>
            <td class="color1">{{ $t('gameAgents.table.td18.text1') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td18.text2') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td18.text3') }}</td>
          </tr>
          <tr>
            <td>19</td>
            <td class="color1">{{ $t('gameAgents.table.td19.text1') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td19.text2') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td19.text3') }}</td>
          </tr>
          <tr>
            <td>20</td>
            <td class="color1">{{ $t('gameAgents.table.td20.text1') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td20.text2') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td20.text3') }}</td>
          </tr>
          <tr>
            <td>21</td>
            <td class="color1">{{ $t('gameAgents.table.td21.text1') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td21.text2') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td21.text3') }}</td>
          </tr>
          <tr>
            <td>22</td>
            <td class="color1">{{ $t('gameAgents.table.td22.text1') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td22.text2') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td22.text3') }}</td>
          </tr>
          <tr>
            <td>23</td>
            <td class="color1">{{ $t('gameAgents.table.td23.text1') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td23.text2') }}</td>
            <td class="color2">{{ $t('gameAgents.table.td23.text3') }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="content3">
      <div class="customer">
        <p class="customer_text">{{ $t('gameAgents.title') }}<br/>{{ $t('gameAgents.text1') }}</p>
        <p class="customer_serve gradient_text">service@deepbrainchain.org</p>
      </div>
    </div>
  </div>
</template>


<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'gameAgents',
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
      };
    }
  })
</script>

<style lang="scss" scoped>
.gameAgents {
  min-width: 1200px;
  margin: auto;
  font-style: normal;
  // font-family: "Noto Sans SC";
  width: 100%;
  overflow: hidden;
  position: relative;
  .bg1 {
    position: absolute;
    top: -500px;
    margin: auto;
    width: 1920px;
    height: 1690px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 1920px;
      height: 1690px;
    }
  }
  .bg2 {
    position: absolute;
    top: 645px;
    margin: auto;
    width: 1200px;
    height: 1200px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 1200px;
      height: 1200px;
    }
  }
  .cont_title {
    font-size: 48px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    margin-bottom: 40px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    max-width: 1200px;
    font-size: 16px;
    color: #899AA9;
    font-weight: 400;
    line-height: 24px; 
    text-align: center;
    letter-spacing: 1px;
  }
  .content1 {
    position: relative;
    width: 1200px;
    margin: auto;
    padding: 100px 0 80px;
    box-sizing: border-box;
    .video {
      width: 800px;
      height: 450px;
      margin: auto;
      background: #000;
    }
  }
  .content2 {
    position: relative;
    width: 1200px;
    margin: auto;
    padding: 100px 0 80px;
    box-sizing: border-box;
    .cont2_text1 {
      color: #FFF;
      font-size: 48px;
      font-weight: 400;
      text-align: center;
      line-height: normal;
      letter-spacing: 1px;
    }
    .showCont {
      width: 1200px;
      display: flex;
      margin: 40px auto;
      padding: 24px 60px;
      align-items: center;
      box-sizing: border-box;
      border: 1px solid #FFF;
      backdrop-filter: blur(12.5px);
      justify-content: space-between;
      box-shadow: 0px 4px 49px 0px rgba(0, 7, 72, 0.12);
      background: linear-gradient(155deg, rgba(255, 255, 255, 0.00) -2.13%, rgba(255, 255, 255, 0.15) 136.58%);
      .showimg1 {
        width: 561px;
        height: 257px;
      }
      .showimg2 {
        width: 487px;
        height: 257px;
      }
    }
    .cont_table {
      width: 100%;
      padding: 24px;
      margin-top: 40px;
      box-sizing: border-box;
      background: rgba(31, 77, 241, 0.04);
      border: 1px solid rgba(255, 255, 255, 0.08);
      .table_cont {
        width: 100%;
        color: #fff;
        font-size: 20px;
        font-weight: 400;
        line-height: 150%;
        text-align: left;
        tr.theader {
          background: rgba(0, 194, 255, 0.16);
          th {
            border: none;
            padding: 20px;
          }
        }
        th {
          padding: 0 20px;
        }
        td {
          padding: 10px 20px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.08);
        }
        .color1 {
          color: #38EED6;
        }
        .color2 {
          color: #41A4FF;
        }
        .width60 {
          width: 60px;
          box-sizing: border-box;
        }
        .width210 {
          width: 210px;
        }
        .width240 {
          width: 240px;
        }
      }
    }
  }

  .content3 {
    position: relative;
    width: 100%;
    padding: 80px 0 160px;
    box-sizing: border-box;
    .customer {
      width: 1200px;
      height: 232px;
      margin: auto;
      display: flex;
      padding: 40px 24px;
      align-items: center;
      align-self: stretch;
      box-sizing: border-box;
      flex-direction: column;
      justify-content: center;
      border-bottom: 1px solid;
      background: #10132F;
      border-image: linear-gradient(to right, #36FFCF, #438EFF) 1;
      .customer_text {
        color: #fff;
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
        text-align: center;
        margin-bottom: 16px;
        letter-spacing: 1px;
      }
      .customer_serve {
        font-size: 40px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1px;
      }
    }
  }
}

</style>
