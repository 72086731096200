<template>
  <div class="cloudInternet" :class="{lan_En : lan != 'zh'}">
    <div class="bg1"><img src="../../assets/home/cont4_bg1.jpg" alt=""></div>
    <div class="bg2"><img src="../../assets/home/cont4_bg2.jpg" alt=""></div>
    <div class="bg3"><img src="../../assets/home/cont4_bg3.png" alt=""></div>
    <div class="content1">
      <div class="cont_title gradient_text">{{ $t('cloudInternet.title') }}</div>
      <div class="cont_desc">{{ $t('cloudInternet.text') }}</div>
      <div class="video">
        <video ref="videoRef" src="/cloudInternet1.mp4"
          loop="loop" 
          muted="muted" 
          controls="controls"
          controlsList="noplaybackrate, nopip">
        </video>
      </div>
    </div>
    <div class="content2">
      <div class="left">
        <p class="text1">{{ $t('cloudInternet.cont2.text1') }}</p>
        <p class="text2 gradient_text">{{ $t('cloudInternet.cont2.text2') }}</p>
        <p class="text3">{{ $t('cloudInternet.cont2.text3') }}</p>
      </div>
      <div class="right">
        <div class="list">
          <div class="listInfo delay500 animateHide" v-animate="{delay: 500, class:'fadeInUp'}">
            <div class="text1"><span class="line color3"></span>{{ $t('cloudInternet.cont2.block1.text1') }}</div>
            <div class="text2">{{ $t('cloudInternet.cont2.block1.text2') }}</div>
          </div>
          <div class="listInfo delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">
            <div class="text1"><span class="line color2"></span>{{ $t('cloudInternet.cont2.block2.text1') }}</div>
            <div class="text2">{{ $t('cloudInternet.cont2.block2.text2') }}</div>
          </div>
        </div>
        <div class="list">
          <div class="listInfo delay900 animateHide" v-animate="{delay: 900, class:'fadeInUp'}">
            <div class="text1"><span class="line color1"></span>{{ $t('cloudInternet.cont2.block3.text1') }}</div>
            <div class="text2">{{ $t('cloudInternet.cont2.block3.text2') }}</div>
          </div>
          <div class="listInfo delay600 animateHide" v-animate="{delay: 600, class:'fadeInUp'}">
            <div class="text1"><span class="line color4"></span>{{ $t('cloudInternet.cont2.block4.text1') }}</div>
            <div class="text2">{{ $t('cloudInternet.cont2.block4.text2') }}</div>
          </div>
          <div class="listInfo delay300 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">
            <div class="text1"><span class="line color5"></span>{{ $t('cloudInternet.cont2.block5.text1') }}</div>
            <div class="text2">{{ $t('cloudInternet.cont2.block5.text2') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content3">
      <div class="videoList">
        <div class="video_title">{{ $t('cloudInternet.cont3.text1') }}</div>
        <div class="video_media">
          <video ref="videoRef" src="/cloudInternet1.mp4"
            loop="loop" 
            muted="muted" 
            controls="controls"
            controlsList="noplaybackrate, nopip">
          </video>
        </div>
      </div>
      <div class="videoList">
        <div class="video_title">{{ $t('cloudInternet.cont3.text2') }}</div>
        <div class="video_media">
          <video ref="videoRef" src="/cloudInternet2.mp4"
            loop="loop" 
            muted="muted" 
            controls="controls"
            controlsList="noplaybackrate, nopip">
          </video>
        </div>
      </div>
    </div>
    <div class="content4">
      <p class="cont4_text1">{{ $t('cloudInternet.cont4.text1') }}</p>
      <p class="cont4_text2 gradient_text">{{ $t('cloudInternet.cont4.text2') }}</p>
      <a class="cont4_btn" download="comparative_data.png" href="/data.png">{{ $t('cloudInternet.cont4.text3') }}</a>
    </div>
    <div class="content5">
      <p class="cont5_text1">{{ $t('cloudInternet.cont5.text1') }}</p>
      <p class="cont5_text2">{{ $t('cloudInternet.cont5.text2') }}</p>
      <p class="cont5_text3">{{ $t('cloudInternet.cont5.text3') }}</p>
      <p class="cont5_text4 gradient_text">{{ $t('cloudInternet.cont5.text4') }}</p>
    </div>
    <div class="content5">
      <p class="cont5_text1">{{ $t('cloudInternet.cont5.text5') }}</p>
      <p class="cont5_text2">{{ $t('cloudInternet.cont5.text6') }}</p>
      <p class="cont5_text3">{{ $t('cloudInternet.cont5.text7') }}</p>
      <p class="cont5_text4 gradient_text">{{ $t('cloudInternet.cont5.text8') }}</p>
    </div>
    <div class="content7">
      <p class="cont7_text1">{{ $t('cloudInternet.cont6.title') }}</p>
      <div class="cont7_btnlist">
        <a download="HyconsCloud.ppsx" href="/HyconsCloud.ppsx" class="cont7_btn cont7_bg1"><p>{{ $t('cloudInternet.cont6.text1') }}</p><span></span></a>
        <a download="Benefit_analysis.pdf" href="/Benefit_analysis.pdf" class="cont7_btn cont7_bg1"><p>{{ $t('cloudInternet.cont6.text2') }}</p><span></span></a>
        <a download="Data_center_architecture.pdf" href="/Data_center_architecture.pdf" class="cont7_btn cont7_bg2"><p>{{ $t('cloudInternet.cont6.text3') }}</p><span></span></a>
        <a download="Install_manual.pdf" href="/Install_manual.pdf" class="cont7_btn cont7_bg1"><p>{{ $t('cloudInternet.cont6.text4') }}</p><span></span></a>
        <a download="DeepLink_Protocol.pdf" href="/DeepLink_Protocol.pdf" class="cont7_btn cont7_bg1"><p>{{ $t('cloudInternet.cont6.text5') }}</p><span></span></a>
      </div>
    </div>
  </div>
</template>


<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'cloudInternet',
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
      };
    }
  })
</script>

<style lang="scss" scoped>
.cloudInternet {
  min-width: 1200px;
  margin: auto;
  font-style: normal;
  // font-family: "Noto Sans SC";
  width: 100%;
  overflow: hidden;
  position: relative;
  .bg1 {
    position: absolute;
    top: -500px;
    margin: auto;
    width: 1920px;
    height: 1690px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 1920px;
      height: 1690px;
    }
  }
  .bg2 {
    position: absolute;
    top: 2490px;
    margin: auto;
    width: 1200px;
    height: 1200px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 1200px;
      height: 1200px;
    }
  }
  .bg3 {
    position: absolute;
    top: 3610px;
    margin: auto;
    width: 1603px;
    height: 1348px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 1603px;
      height: 1348px;
    }
  }
  .cont_title {
    font-size: 48px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    margin-bottom: 40px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    max-width: 1200px;
    font-size: 16px;
    color: #899AA9;
    font-weight: 400;
    line-height: 24px; 
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 40px;
  }
  .content1 {
    position: relative;
    width: 1200px;
    margin: auto;
    padding: 100px 0 80px;
    box-sizing: border-box;
    .video {
      width: 800px;
      height: 450px;
      margin: auto;
      background: #000;
      video {
        width: 800px;
        height: 450px;
      }
    }
  }
  .content2 {
    position: relative;
    width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    padding: 80px 0 100px;
    box-sizing: border-box;
    justify-content: space-between;
    .left {
      width: 400px;
      display: flex;
      margin-right: 100px;
      flex-direction: column;
      .text1 {
        color: #fff;
        font-size: 48px;
        font-weight: 500;
        margin-bottom: 12px;
        line-height: normal;
        letter-spacing: 0.96px;
      }
      .text2 {
        font-size: 48px;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 12px;
        letter-spacing: 0.96px;
      }
      .text3 {
        color: #899AA9;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 1px;
      }
    }
    .right {
      flex: 1;
      // height: 540px;
      display: flex;
      justify-content: space-between;
      .list {
        width: 340px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .listInfo {
          width: 100%;
          padding: 24px;
          margin-top: 15px;
          box-sizing: border-box;
          backdrop-filter: blur(2px);
          background: rgba(0, 0, 0, 0.24);
          .text1 {
            display: flex;
            color: #fff;
            font-size: 24px;
            font-weight: 500;
            line-height: 32px;
            align-items: center;
            margin-bottom: 10px;
            .line {
              width: 2px;
              height: 28px;
              margin-right: 15px;
              &.color3 {
                background: #9945FF;
              }
              &.color2 {
                background: #2059FE;
              }
              &.color1 {
                background: #16C8FF;
              }
              &.color6 {
                background: #FF5D39;
              }
              &.color5 {
                background: #FFD512;
              }
              &.color4 {
                background: #19FB9B;
              }
            }
          }
          .text2 {
            font-size: 16px;
            color: #899AA9;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    }
  }
  .content3 {
    position: relative;
    width: 1200px;
    margin: auto;
    display: flex;
    padding: 80px 0;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
    .videoList {
      width: 588px;
      padding: 40px;
      box-sizing: border-box;
      background: rgba(16, 19, 47, .6);
      border-bottom: 1px solid;
      border-image: linear-gradient(to right, #36FFCF, #438EFF) 1;
      .video_title {
        color: #fff;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        line-height: normal;
        margin-bottom: 24px;
        letter-spacing: 0.48px;
      }
      .video_media {
        width: 508px;
        height: 280px;
        background: #000;
        video {
          width: 508px;
          height: 280px;
        }
      }
    }
  }
  .content4 {
    position: relative;
    width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    padding: 100px 0 80px;
    box-sizing: border-box;
    flex-direction: column;
    .cont4_text1 {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 1px;
    }
    .cont4_text2 {
      font-size: 48px;
      font-weight: 400;
      text-align: center;
      line-height: normal;
      letter-spacing: 1px;
      margin: 24px 0 40px;
    }
    .cont4_btn {
      // width: 320px;
      height: 48px;
      margin: auto;
      color: #fff;
      font-size: 16px;
      padding: 0 24px;
      font-weight: 500;
      line-height: 48px;
      text-align: center;
      text-decoration: none;
      box-sizing: border-box;
      border: 1px solid #FFF;
    }
  }
  .content5 {
    position: relative;
    width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    padding: 100px 0 80px;
    box-sizing: border-box;
    flex-direction: column;
    .cont5_text1 {
      color: #FFF;
      font-size: 48px;
      font-weight: 500;
      text-align: center;
      line-height: normal;
      letter-spacing: 0.96px;
    }
    .cont5_text2 {
      padding: 0 60px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #899AA9;
      text-align: center;
      letter-spacing: 1px;
      margin-top: 40px;
      box-sizing: border-box;
    }
    .cont5_text3 {
      color: #FFF;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin: 24px 0;
      letter-spacing: 1px;
    }
    .cont5_text4 {
      font-size: 48px;
      font-weight: 400;
      text-align: center;
      line-height: normal;
      letter-spacing: 1px;
    }
  }
  .content7 {
    position: relative;
    width: 1200px;
    margin: auto;
    padding: 100px 0 80px;
    box-sizing: border-box;
    .cont7_text1 {
      color: #FFF;
      font-size: 48px;
      font-weight: 500;
      text-align: center;
      line-height: normal;
      letter-spacing: 0.96px;
    }
    .cont7_btnlist {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 0 100px;
      margin-top: 40px;
      align-items: center;
      box-sizing: border-box;
      justify-content: space-around;
      .cont7_btn {
        margin-bottom: 10px;
        display: flex;
        cursor: pointer;
        padding: 8px 16px;
        align-items: center;
        text-decoration: none;
        &.cont7_bg1 {
          background: #41A4FF;
        }
        &.cont7_bg2 {
          background: linear-gradient(90deg, #36FFCF -3.33%, #438EFF 100%);
        }
        p {
          color: #FFF;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 1px;
        }
        span {
          display: inline-block;
          width: 16px;
          height: 16px;
          background: url(../../assets/help/download.png) no-repeat 100%/100%;
        }
      }
    }
  }
}

</style>
