import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/home.vue'
import deployCloud from '../views/help/deployCloud.vue'
import cloudInternet from '../views/help/cloudInternet.vue'
import gameAgents from '../views/help/gameAgents.vue'
import convertGame from '../views/help/convertGame.vue'
import applyForFunding from '../views/help/applyForFunding.vue'
import createwallet from '../views/wallet/createwallet.vue'
import polkaWallet from '../views/wallet/polkaWallet.vue'
import mathWallet from '../views/wallet/MathWallet.vue'
import ERC20Wallet from '../views/wallet/ERC20Wallet.vue'
import TrustWallet from '../views/wallet/TrustWallet.vue'
import metaMask from '../views/wallet/metaMask.vue'
import ImToken from '../views/wallet/ImToken.vue'
import buyDBC from '../views/buyDBC.vue'
import learnDBC from '../views/learn/learnDBC.vue'
import whatisGPU from '../views/learn/whatisGPU.vue'
import historyDBC from '../views/learn/historyDBC.vue'
import introDBC from '../views/learn/introDBC.vue'
import aboutTeam from '../views/learn/aboutTeam.vue'
import buyGpuServer from '../views/wiki/buyGpuServer.vue'
import getReward from '../views/wiki/getReward.vue'
import voteReward from '../views/wiki/voteReward.vue'
import referenda from '../views/wiki/referenda.vue'
import applyCouncil from '../views/wiki/applyCouncil.vue'
import councilVoting from '../views/wiki/councilVoting.vue'
import gpuMiner from '../views/wiki/gpuMiner.vue'

import whatisAi from '../views/aipro/whatisAi.vue'
import developAi from '../views/aipro/developAi.vue'
import deployToken from '../views/aipro/deployToken.vue'
import paymentRelated from '../views/aipro/paymentRelated.vue'
import gpuMining from '../views/aipro/gpuMining.vue'
import developAIModel from '../views/aipro/developAIModel.vue'
import fullProcessTesting from '../views/aipro/fullProcessTesting.vue'
import primaryProcessTesting from '../views/aipro/primaryProcessTesting.vue'
import participateDBCMining from '../views/miner/participateDBCMining.vue'
import participateDBCDLCMining from '../views/miner/participateDBCDLCMining.vue'
import participateDBCAiMining from '../views/miner/participateDBCAiMining.vue'
import participateAiMining from '../views/miner/participateAiMining.vue'
import participateGPUMining from '../views/miner/participateGPUMining.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/deployCloud',
    name: 'deployCloud',
    component: deployCloud
  },
  {
    path: '/cloudInternet',
    name: 'cloudInternet',
    component: cloudInternet
  },
  {
    path: '/gameAgents',
    name: 'gameAgents',
    component: gameAgents
  },
  {
    path: '/convertGame',
    name: 'convertGame',
    component: convertGame
  },
  {
    path: '/applyForFunding',
    name: 'applyForFunding',
    component: applyForFunding
  },
  {
    path: '/createwallet',
    name: 'createwallet',
    component: createwallet
  },
  {
    path: '/polkaWallet',
    name: 'polkaWallet',
    component: polkaWallet
  },
  {
    path: '/mathWallet',
    name: 'mathWallet',
    component: mathWallet
  },
  {
    path: '/erc20Wallet',
    name: 'erc20Wallet',
    component: ERC20Wallet
  },
  {
    path: '/ImToken',
    name: 'ImToken',
    component: ImToken
  },
  {
    path: '/TrustWallet',
    name: 'TrustWallet',
    component: TrustWallet
  },
  {
    path: '/metaMask',
    name: 'metaMask',
    component: metaMask
  },
  {
    path: '/buyDBC',
    name: 'buyDBC',
    component: buyDBC
  },
  {
    path: '/learnDBC',
    name: 'learnDBC',
    component: learnDBC
  },
  {
    path: '/whatisGPU',
    name: 'whatisGPU',
    component: whatisGPU
  },
  {
    path: '/historyDBC',
    name: 'historyDBC',
    component: historyDBC
  },
  {
    path: '/introDBC',
    name: 'introDBC',
    component: introDBC
  },
  {
    path: '/aboutTeam',
    name: 'aboutTeam',
    component: aboutTeam
  },
  {
    path: '/buyGpuServer',
    name: 'buyGpuServer',
    component: buyGpuServer
  },
  {
    path: '/getReward',
    name: 'getReward',
    component: getReward
  },
  {
    path: '/voteReward',
    name: 'voteReward',
    component: voteReward
  },
  {
    path: '/referenda',
    name: 'referenda',
    component: referenda
  },
  {
    path: '/applyCouncil',
    name: 'applyCouncil',
    component: applyCouncil
  },
  {
    path: '/councilVoting',
    name: 'councilVoting',
    component: councilVoting
  },
  {
    path: '/gpuMiner',
    name: 'gpuMiner',
    component: gpuMiner
  },

  
  {
    path: '/whatisAi',
    name: 'whatisAi',
    component: whatisAi
  },
  {
    path: '/developAi',
    name: 'developAi',
    component: developAi
  },
  {
    path: '/deployToken',
    name: 'deployToken',
    component: deployToken
  },
  {
    path: '/paymentRelated',
    name: 'paymentRelated',
    component: paymentRelated
  },
  {
    path: '/gpuMining',
    name: 'gpuMining',
    component: gpuMining
  },
  {
    path: '/developAIModel',
    name: 'developAIModel',
    component: developAIModel
  },
  {
    path: '/fullProcessTesting',
    name: 'fullProcessTesting',
    component: fullProcessTesting
  },
  {
    path: '/primaryProcessTesting',
    name: 'primaryProcessTesting',
    component: primaryProcessTesting
  },
  {
    path: '/participateDBCMining',
    name: 'participateDBCMining',
    component: participateDBCMining
  },
  {
    path: '/participateDBCDLCMining',
    name: 'participateDBCDLCMining',
    component: participateDBCDLCMining
  },
  {
    path: '/participateDBCAiMining',
    name: 'participateDBCAiMining',
    component: participateDBCAiMining
  },
  {
    path: '/participateAiMining',
    name: 'participateAiMining',
    component: participateAiMining
  },
  {
    path: '/participateGPUMining',
    name: 'participateGPUMining',
    component: participateGPUMining
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  }
})
// router.beforeEach((to, from, next) => {
//   document.body.scrollTop = 0
//   document.documentElement.scrollTop = 0
//   window.pageYOffset = 0
//   next()
// })
export default router
