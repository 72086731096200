<template>
  <div class="primaryProcessTesting" :class="{lan_En : lan != 'zh'}">
    <div class="pagecont1">
      <div class="title">
        <p>{{ $t('primaryProcessTesting.title') }}</p>
      </div>
      <!-- <div class="bg1"><img src="../../assets/aipro/aibg2.png" alt=""></div> -->
      <div class="pagecont">
        <div class="cont_right">
          <div class="bg">
            <img src="../../assets/aipro/aibg5.png" alt="">
          </div>
          <img class="cont_img delay100 animateHide" v-animate="{delay: 100, class:'amplify'}" src="../../assets/aipro/ai_img8.png" alt="">
        </div>
        <div class="cont_left">
          <div class="title1 cont_desc">{{ $t('primaryProcessTesting.text') }}</div>
          <div class="list">
            <div class="listInfo">
              <div class="cont_desc mb20 delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">{{ $t('primaryProcessTesting.text1') }}</div>
              <div class="cont_desc mb20 delay200 animateHide" v-animate="{delay: 200, class:'fadeInUp'}">{{ $t('primaryProcessTesting.text2') }}</div>
              <div class="cont_desc mb20 delay300 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">{{ $t('primaryProcessTesting.text3') }}</div>
              <div class="cont_desc mb20 delay400 animateHide" v-animate="{delay: 400, class:'fadeInUp'}">{{ $t('primaryProcessTesting.text4') }}</div>
              <div class="cont_desc delay500 animateHide" v-animate="{delay: 500, class:'fadeInUp'}">{{ $t('primaryProcessTesting.text5') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'primaryProcessTesting',
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
      };
    }
  })
</script>

<style lang="scss" scoped>
.primaryProcessTesting {
  margin: auto;
  font-style: normal;
  width: 100%;
  overflow: hidden;
  position: relative;
  .cont_title {
    color: #fff;
    font-size: 32px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    font-size: 16px;
    line-height: 24px; 
    letter-spacing: 1px;
    color: rgba(255, 255, 255, .6);
  }
  .pagecont1 {
    position: relative;
    width: 100%;
    margin: auto;
    max-width: 1200px;
    padding: 80px 0 100px;
    box-sizing: border-box;    
    .bg1 {
      position: absolute;
      top: -200px;
      left: -100px;
      margin: auto;
      width: 700px;
      height: 700px;
      img {
        width: 700px;
        height: 700px;
      }
    }
    .title {
      text-align: right;
      font-size: 48px;
      font-weight: 600;
      p {
        display: inline-block;
        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(90deg, #03FFF0 30%, #6409F7 70%);
      }
    }
    .pagecont {
      display: flex;
      text-align: left;
      justify-content: space-between;
      .cont_left {
        width: 660px;
        display: flex;
        margin-top: 60px;
        flex-direction: column;
        .title1 {
          text-align: right;
          margin-bottom: 36px;
        }
        .list {
          width: 100%;
          display: flex;
          border-radius: 10px;
          flex-direction: column;
          justify-content: center;
          background-image: linear-gradient(0deg, #03FFF0 30%, #6409F7 70%);
          .listInfo {
            box-sizing: border-box;
            background: #03061D;
            margin-right: 1px;
            border-radius: 10px;
            padding: 0 20px 0 30px;
            &:last-child {
              margin-bottom: 0;
            }
          }
          .mb20 {
            margin-bottom: 30px;
          }
          .ml20 {
            margin-left: 20px;
          }
          a {
            color: rgba(255, 255, 255, .6);
          }
          .text1 {
            color: #9DFFD4;
            .nav1 {
              color: #9DFFD4;
            }
          }
        }
      }
      .cont_right {
        position: relative;
        width: 430px;
        display: flex;
        flex-direction: column;
        .bg {
          position: absolute;
          right: -560px;
          top: -390px;
          width: 1500px;
          z-index: 1;
          img {
            width: 1500px;
          }
        }
        .cont_img {
          width: 400px;
        }
      }
    }
    
  }
}
</style>
