<template>
  <div class="polkaWallet" :class="{lan_En : lan != 'zh'}">
    <div class="bg1"><img src="../../assets/home/cont4_bg1.jpg" alt=""></div>
    <div class="content1">
      <div class="cont_title gradient_text">{{ $t('polkaWallet.title') }}</div>
      <div class="cont_desc">{{ $t('polkaWallet.text') }}</div>
      <!-- <div class="cont_btn">{{ $t('polkaWallet.text1') }}</div> -->
      <a target="_blank" class="cont_btn" href="https://polkawallet.io/">{{ $t('polkaWallet.text1') }}</a>
    </div>
    <div class="content2">
      <p class="cont2_text1">{{ $t('polkaWallet.step.title') }}</p>
      <div class="procedure_wiki" v-show="lan == 'zh'">
        <div class="procedure_list">
          <p class="procedure_text4">{{ $t('polkaWallet.step.text1') }}</p>
          <img class="procedure_img" src="../../assets/wiki/polkawallet/1.jpg" alt="">
        </div>
        <div class="procedure_list">
          <p class="procedure_text4">{{ $t('polkaWallet.step.text2') }}</p>
          <img class="procedure_img" src="../../assets/wiki/polkawallet/2.jpg" alt="">
        </div>
        <div class="procedure_list">
          <p class="procedure_text4">{{ $t('polkaWallet.step.text3') }}</p>
          <img class="procedure_img" src="../../assets/wiki/polkawallet/3.jpg" alt="">
        </div>
        <div class="procedure_list">
          <p class="procedure_text4">{{ $t('polkaWallet.step.text4') }}</p>
          <img class="procedure_img" src="../../assets/wiki/polkawallet/4.jpg" alt="">
        </div>
        <div class="procedure_list">
          <p class="procedure_text4">{{ $t('polkaWallet.step.text5') }}</p>
          <img class="procedure_img" src="../../assets/wiki/polkawallet/5.jpg" alt="">
        </div>
        <div class="procedure_list">
          <p class="procedure_text4">{{ $t('polkaWallet.step.text6') }}</p>
          <img class="procedure_img" src="../../assets/wiki/polkawallet/6.jpg" alt="">
        </div>
        <div class="procedure_list">
          <p class="procedure_text4">{{ $t('polkaWallet.step.text7') }}</p>
          <img class="procedure_img" src="../../assets/wiki/polkawallet/7.jpg" alt="">
        </div>
        <div class="procedure_list">
          <p class="procedure_text4">{{ $t('polkaWallet.step.text8') }}</p>
          <img class="procedure_img" src="../../assets/wiki/polkawallet/8.jpg" alt="">
        </div>
        <div class="procedure_list">
          <p class="procedure_text4">{{ $t('polkaWallet.step.text9') }}</p>
          <img class="procedure_img" src="../../assets/wiki/polkawallet/9.jpg" alt="">
        </div>
        <div class="procedure_list">
          <p class="procedure_text4">{{ $t('polkaWallet.step.text10') }}</p>
          <img class="procedure_img" src="../../assets/wiki/polkawallet/10.jpg" alt="">
        </div>
      </div>
      <div class="procedure_wiki" v-show="lan != 'zh'">
        <div class="procedure_list">
          <p class="procedure_text4">1. Open your browser and enter polkawallet.io,then download the application for your device.</p>
          <img class="procedure_img" src="../../assets/wiki/polkawallet/polkaWallet_En1.png" alt="">
        </div>
        <div class="procedure_list">
          <p class="procedure_text4">2. After downloading, open the Polka Wallet application. To create a wallet, simply click on Create Account and then select> Substrate account</p>
          <img class="procedure_img" src="../../assets/wiki/polkawallet/polkaWallet_En2.png" alt="">
        </div>
        <div class="procedure_list">
          <p class="procedure_text4">3. After creating wallet, please save your wallet information carefully in a safe place.</p>
        </div>
        <div class="procedure_list">
          <p class="procedure_text4">4. Add DBC in Polka Wallet.</p>
          <img class="procedure_img" src="../../assets/wiki/polkawallet/polkaWallet_En3.png" alt="">
        </div>
        <div class="procedure_list">
          <p class="procedure_text4">5. Click on the wallet icon at the top and select DBC</p>
          <img class="procedure_img" src="../../assets/wiki/polkawallet/polkaWallet_En4.png" alt="">
        </div>
        <div class="procedure_list">
          <p class="procedure_text4">6. To check your wallet address, simply click on the receive icon. To transfer DBC, you can use the transfer button.</p>
          <img class="procedure_img" src="../../assets/wiki/polkawallet/polkaWallet_En5.png" alt="">
        </div>
      </div>
    </div>
    <div class="content3">
      <div class="contbg"><ComponentWave></ComponentWave></div>
      <div class="cont3_media">
        <p class="cont3_media_text">{{ $t('createwallet.title1') }}</p>
        <div class="cont3_media_video">
          <video v-if="lan == 'zh'" ref="videoRef" src="/video/polkawallet/polkadotWallet.mp4"
            loop="loop" 
            muted="muted" 
            controls="controls"
            controlsList="noplaybackrate, nopip">
          </video>
          <video v-else ref="videoRef" src="/video/polkawallet/PolkaWallet_En.mp4"
            loop="loop" 
            muted="muted" 
            controls="controls"
            controlsList="noplaybackrate, nopip">
          </video>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import ComponentWave from "@/components/component_wave.vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'polkaWallet',
    components: {
      ComponentWave
    },
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
      };
    }
  })
</script>

<style lang="scss" scoped>
.polkaWallet {
  min-width: 1200px;
  margin: auto;
  font-style: normal;
  // font-family: "Noto Sans SC";
  width: 100%;
  overflow: hidden;
  position: relative;
  .bg1 {
    position: absolute;
    top: -500px;
    margin: auto;
    width: 1920px;
    height: 1690px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 1920px;
      height: 1690px;
    }
  }
  .cont_title {
    font-size: 48px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    margin-bottom: 40px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    max-width: 1000px;
    font-size: 16px;
    color: #899AA9;
    font-weight: 400;
    line-height: 24px; 
    text-align: center;
    letter-spacing: 1px;
  }
  .content1 {
    position: relative;
    width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 100px 0 80px;
    box-sizing: border-box;
    .cont_btn {
      margin: auto;
      color: #FFF;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      margin-top: 40px;
      padding: 10px 60px;
      line-height: normal;
      transition: all .5s;
      text-decoration: none;
      border: 1px solid #FFF;
      &:hover {
        color: #10132F;
        background: #FFF;
        border: 1px solid #FFF;
      }
    }
  }
  .content2 {
    position: relative;
    width: 1200px;
    margin: auto;
    padding: 100px 0 80px;
    box-sizing: border-box;
    .cont2_text1 {
      color: #FFF;
      font-size: 48px;
      font-weight: 400;
      text-align: center;
      line-height: normal;
      letter-spacing: 1px;
    }
    .cont2_text2 {
      font-size: 16px;
      color: #899AA9;
      font-weight: 400;
      margin-top: 40px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 1px;
    }
    .procedure_wiki {
      display: flex;
      flex-wrap: wrap;
      .procedure_list {
        width: 375px;
        padding: 0 20px;
        box-sizing: border-box;
      }
      .procedure_img {
        width: 320px;
      }
    }
  }
  .content3 {
    position: relative;
    width: 100%;
    padding: 100px 0 160px;
    .contbg {
      position: absolute;
      margin: auto;
      bottom: 0;
      width: 1900px;
      height: 430px;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 1900px;
        height: 430px;
      }
    }
    .cont3_media {
      position: relative;
      width: 1200px;
      margin: 0 auto;
      .cont3_media_text {
        color: #FFF;
        font-size: 48px;
        font-weight: 400;
        text-align: center;
        line-height: normal;
        letter-spacing: 1px;
        margin-bottom: 40px;
      }
      .cont3_media_video {
        width: 800px;
        height: 450px;
        margin: 0 auto;
        background: #000;
        video {
          width: 800px;
          height: 450px;
        }
      }
    }
  }
}
</style>
