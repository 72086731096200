<template>
  <div class="deployCloud" :class="{lan_En : lan != 'zh'}">
    <div class="bg1"><img src="../../assets/home/cont4_bg1.jpg" alt=""></div>
    <div class="content1">
      <div class="cont_title gradient_text">{{ $t('deployCloud.title') }}</div>
      <div class="stepList">
        <div class="stepLi delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">
          <div class="step_num">
            <p>01</p>
            <div class="step_line"></div>
          </div>
          <div class="step_content">
            <div class="step_cont_title">{{ $t('deployCloud.step1.text1') }}</div>
            <div class="step_cont_text">{{ $t('deployCloud.step1.text2') }}<a href="https://deepbrainchain.github.io/DBC-Wiki/staking-model/run-archive-node.html">https://deepbrainchain.github.io/DBC-Wiki/staking-model/run-archive-node.html</a></div>
            <div class="step_cont_text"><span class="step_icon"></span> {{ $t('deployCloud.step1.text3') }}</div>
            <div class="step_cont_text"><span class="step_icon"></span> {{ $t('deployCloud.step1.text4') }}</div>
            <div class="step_cont_text"><span class="step_icon"></span> {{ $t('deployCloud.step1.text5') }}</div>
          </div>
        </div>
        <div class="stepLi delay200 animateHide" v-animate="{delay: 200, class:'fadeInUp'}">
          <div class="step_num">
            <p>02</p>
            <div class="step_line"></div>
          </div>
          <div class="step_content">
            <div class="step_cont_title">{{ $t('deployCloud.step2.text1') }}</div>
            <div class="step_cont_text">{{ $t('deployCloud.step2.text2') }}</div>
            <div class="step_cont_text"><span class="step_icon"></span> {{ $t('deployCloud.step2.text3') }}</div>
            <div class="step_cont_text"><span class="step_icon"></span> {{ $t('deployCloud.step2.text4') }}</div>
            <div class="step_cont_text"><span class="step_icon"></span> {{ $t('deployCloud.step2.text5') }}</div>
          </div>
        </div>
        <div class="stepLi delay300 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">
          <div class="step_num">
            <p>03</p>
            <div class="step_line"></div>
          </div>
          <div class="step_content">
            <div class="step_cont_title">{{ $t('deployCloud.step3.text1') }}</div>
            <div class="step_cont_text">{{ $t('deployCloud.step3.text2') }}</div>
            <div class="step_cont_text"><span class="step_icon"></span> {{ $t('deployCloud.step3.text3') }}</div>
            <div class="step_cont_text"><span class="step_icon"></span> {{ $t('deployCloud.step3.text4') }}</div>
            <div class="step_cont_text"><span class="step_icon"></span> {{ $t('deployCloud.step3.text5') }}</div>
            <div class="step_cont_text"><span class="step_icon"></span> {{ $t('deployCloud.step3.text6') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content2">
      <div class="contbg"><ComponentWave></ComponentWave></div>
      <div class="customer">
        <p class="customer_text">{{ $t('deployCloud.server') }}</p>
        <p class="customer_serve gradient_text">service@deepbrainchain.org</p>
      </div>
    </div>
  </div>
</template>


<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import ComponentWave from "@/components/component_wave.vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'deployCloud',
    components: {
      ComponentWave
    },
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
      };
    }
  })
</script>

<style lang="scss" scoped>
.deployCloud {
  min-width: 1200px;
  margin: auto;
  font-style: normal;
  // font-family: "Noto Sans SC";
  width: 100%;
  overflow: hidden;
  position: relative;
  .bg1 {
    position: absolute;
    top: -500px;
    margin: auto;
    width: 1920px;
    height: 1690px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 1920px;
      height: 1690px;
    }
  }
  .cont_title {
    position: relative;
    z-index: 1;
    font-size: 48px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    margin-bottom: 40px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    font-size: 16px;
    color: #899AA9;
    font-weight: 400;
    line-height: 24px; 
    text-align: center;
    letter-spacing: 1px;
  }
  .content1 {
    position: relative;
    width: 1200px;
    margin: auto;
    padding: 80px 0;
    box-sizing: border-box;
    .stepList {
      width: 100%;
      margin-top: 40px;
      .stepLi {
        width: 1200px;
        display: flex;
        margin-bottom: 24px;
        justify-content: space-between;
        .step_content {
          flex: 1;
          display: flex;
          line-height: 150%;
          flex-direction: column;
          .step_cont_title {
            color: #fff;
            font-size: 24px;
            font-weight: 700;
            margin-top: 18px;
          }
          .step_cont_text {
            font-size: 16px;
            color: #899AA9;
            font-weight: 500;
            margin-top: 12px;
            .step_icon {
              display: inline-block;
              width: 12px;
              height: 12px;
              margin-top: 6px;
              margin-right: 16px;
              background: url(../../assets/home/cont5_icon.png) 100%/100% no-repeat;
            }
          }
        }
        .step_num {
          width: 80px;
          display: flex;
          align-items: center;
          flex-direction: column;
          p {
            color: rgba(255, 255, 255, 0.24);
            font-size: 40px;
            font-weight: 900;
            line-height: 150%; 
          }
          .step_line {
            width: 2px;
            margin-top: 5px;
            height: -webkit-fill-available;
            background: linear-gradient(180deg, #36FFCF -3.33%, #438EFF 100%);
          }
        }
      }
    }
  }
  .content2 {
    position: relative;
    width: 100%;
    height: 430px;
    .contbg {
      position: absolute;
      margin: auto;
      width: 1900px;
      height: 430px;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 1900px;
        height: 430px;
      }
    }
    .customer {
      position: absolute;
      width: 1152px;
      height: 186px;
      box-sizing: border-box;
      top: 125px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      padding: 40px 24px;
      align-items: center;
      align-self: stretch;
      background: rgba(16, 19, 47, .6);
      flex-direction: column;
      justify-content: center;
      border-bottom: 1px solid;
      border-image: linear-gradient(to right, #36FFCF, #438EFF) 1;
      .customer_text {
        color: #fff;
        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 24px;
        letter-spacing: 1px;
      }
      .customer_serve {
        font-size: 40px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1px;
      }
    }
  }
}

</style>
