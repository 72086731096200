import en from './EN'
import zh from './CN'
import ko from './KO'
import ja from './JA'
import ru from './RU'
import vn from './VN'

export default {
  en,
  zh,
  ko,
  ja,
  ru,
  vn
}