<template>
  <div class="participateDBCDLCMining" :class="{lan_En : lan != 'zh'}">
    <div class="pagecont1">
      <div class="title"><p>{{ $t('participateDBCDLCMining.title') }}</p></div>
      <div class="text"><p>{{ $t('participateDBCDLCMining.text') }}</p></div>
      <div class="bg1"><img src="../../assets/aipro/aibg2.png" alt=""></div>
      <div class="pagecont">
        <div class="cont_left">
          <div class="list">
            <div class="listInfo delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">
              <p class="cont_desc">{{ $t('participateDBCDLCMining.text1') }} <a class="nav1" href="https://orion.deeplink.cloud/longterm" target="_blank">https://orion.deeplink.cloud/longterm</a></p>
            </div>
            <div class="listInfo delay500 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">
              <p class="cont_desc">{{ $t('participateDBCDLCMining.text2') }}</p>
            </div>
            <div class="listInfo delay500 animateHide" v-animate="{delay: 500, class:'fadeInUp'}">
              <p class="cont_desc">{{ $t('participateDBCDLCMining.text3_1') }}
                <!-- <a class="nav1" href="https://galaxyrace.deepbrainchain.org/rule" target="_blank">https://galaxyrace.deepbrainchain.org/rule</a>
                <br/> -->
                <a class="nav1" href="https://orion.deeplink.cloud/longterm" target="_blank">https://orion.deeplink.cloud/longterm</a>
              </p>
              <p class="cont_desc">{{ $t('participateDBCDLCMining.text3_2') }}</p>
            </div>
          </div>
        </div>
        <div class="cont_right">
          <div class="bg">
            <img src="../../assets/aipro/aibg5.png" alt="">
          </div>
          <img class="cont_img delay100 animateHide" v-animate="{delay: 100, class:'amplify'}" src="../../assets/miner/miner_img1.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'participateDBCDLCMining',
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
      };
    }
  })
</script>

<style lang="scss" scoped>
.participateDBCDLCMining {
  margin: auto;
  font-style: normal;
  width: 100%;
  overflow: hidden;
  position: relative;
  .cont_title {
    color: #fff;
    font-size: 32px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    font-size: 16px;
    line-height: 24px; 
    letter-spacing: 1px;
    color: rgba(255, 255, 255, .6);
  }
  .pagecont1 {
    position: relative;
    width: 100%;
    margin: auto;
    max-width: 1200px;
    padding: 80px 0 100px;
    box-sizing: border-box;    
    .bg1 {
      position: absolute;
      top: -200px;
      left: -100px;
      margin: auto;
      width: 700px;
      height: 700px;
      img {
        width: 700px;
        height: 700px;
      }
    }
    .title {
      position: relative;
      z-index: 2;
      width: 800px;
      font-size: 48px;
      font-weight: 600;
      margin-bottom: 80px;
      p {
        display: inline-block;
        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(90deg, #03FFF0 30%, #6409F7 70%);
      }
    }
    .text {
      position: relative;
      z-index: 2;
      color: #fff;
      width: 650px;
      font-size: 26px;
      font-weight: 600;
      line-height: 44px;
      margin-bottom: 40px;
    }
    .pagecont {
      display: flex;
      text-align: left;
      justify-content: space-between;
      .cont_left {
        position: relative;
        z-index: 2;
        width: 650px;
        display: flex;
        flex-direction: column;
        .list {
          width: 100%;
          display: flex;
          border-radius: 10px;
          flex-direction: column;
          justify-content: center;
          .listInfo {
            width: 100%;
            padding: 30px;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 10px;
            border-radius: 20px;
            box-sizing: border-box;
            background: rgba(255 ,255, 255, .05);
            &:last-child {
              margin-bottom: 0;
            }
          }
          .mb20 {
            margin-bottom: 20px;
          }
          .ml20 {
            margin-left: 20px;
          }
          a {
            color: rgba(255, 255, 255, .6);
          }
          .text1 {
            color: #9DFFD4;
            .nav1 {
              color: #9DFFD4;
            }
          }
        }
      }
      .cont_right {
        position: absolute;
        right: -50px;
        top: 40px;
        width: 530px;
        .bg {
          position: absolute;
          right: -560px;
          top: -390px;
          width: 1500px;
          z-index: 1;
          img {
            width: 1500px;
          }
        }
        .cont_img {
          width: 530px;
        }
      }
    }
    
  }
}
</style>
