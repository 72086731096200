<template>
  <div class="header" :class="{lan_En : lan != 'zh', lan_Ru: (lan == 'ru' || lan == 'vn' || lan == 'ja')}">
    <div class="meau">
      <div class="content">
        <img class="logo" src="../assets/logo.png" alt="deeplink logo" @click="linkHref('/')">
        <div class="nav">
          <!-- <div class="nav1" :class="{active: href == '/'}" @click="linkHref('/')">{{$t('meau.nav')}}</div> -->
          <div class="nav_new">
            <el-dropdown popper-class="demo-dropdown" @command="linkHref2">
              <span class="el-dropdown-link">{{$t('meau.nav1')}}</span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="/buyGpuServer">{{$t('meau.nav1_1')}}</el-dropdown-item>
                  <el-dropdown-item command="/getReward">{{$t('meau.nav1_2')}}</el-dropdown-item>
                  <el-dropdown-item command="/voteReward">{{$t('meau.nav1_3')}}</el-dropdown-item>
                  <el-dropdown-item command="/gpuMiner">{{$t('meau.nav1_4')}}</el-dropdown-item>
                  <el-dropdown-item  command="/applyCouncil">{{$t('meau.nav1_5')}}</el-dropdown-item>
                  <el-dropdown-item command="/councilVoting">{{$t('meau.nav1_6')}}</el-dropdown-item>
                  <el-dropdown-item command="/referenda">{{$t('meau.nav1_7')}}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <img class="icon2" src="../assets/arrow.png" alt="">
          </div>
          <div class="nav_new">
            <el-dropdown popper-class="demo-dropdown" @command="linkHref2">
              <span class="el-dropdown-link">{{$t('meau.nav2')}}</span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="/learnDBC">{{$t('meau.nav2_1')}}</el-dropdown-item>
                  <el-dropdown-item command="/whatisGPU">{{$t('meau.nav2_2')}}</el-dropdown-item>
                  <el-dropdown-item command="/historyDBC">{{$t('meau.nav2_3')}}</el-dropdown-item>
                  <el-dropdown-item command="/Whitepaper">{{$t('meau.nav2_4')}}</el-dropdown-item>
                  <el-dropdown-item command="/WiKi">{{$t('meau.nav5')}}</el-dropdown-item>
                  <el-dropdown-item command="/introDBC">{{$t('meau.nav2_5')}}</el-dropdown-item>
                  <el-dropdown-item command="/aboutTeam">{{$t('meau.nav2_6')}}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <img class="icon2" src="../assets/arrow.png" alt="">
          </div>
          <div class="nav_new">
            <el-dropdown popper-class="demo-dropdown" @command="linkHref2">
              <span class="el-dropdown-link">{{$t('meau.nav9')}}</span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="/whatisAi">{{$t('meau.nav9_1')}}</el-dropdown-item>
                  <el-dropdown-item command="/developAi">{{$t('meau.nav9_2')}}</el-dropdown-item>
                  <el-dropdown-item command="/deployToken">{{$t('meau.nav9_3')}}</el-dropdown-item>
                  <el-dropdown-item command="/paymentRelated">{{$t('meau.nav9_4')}}</el-dropdown-item>
                  <el-dropdown-item command="/gpuMining">{{$t('meau.nav9_5')}}</el-dropdown-item>
                  <el-dropdown-item command="/developAIModel">{{$t('meau.nav9_6')}}</el-dropdown-item>
                  <el-dropdown-item command="/fullProcessTesting">{{$t('meau.nav9_7')}}</el-dropdown-item>
                  <el-dropdown-item command="/primaryProcessTesting">{{$t('meau.nav9_8')}}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <img class="icon2" src="../assets/arrow.png" alt="">
          </div>
          <div class="nav_new">
            <el-dropdown popper-class="demo-dropdown" @command="linkHref2">
              <span class="el-dropdown-link">{{$t('meau.nav10')}}</span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="/participateDBCMining">{{$t('meau.nav10_1')}}</el-dropdown-item>
                  <el-dropdown-item command="/participateDBCDLCMining">{{$t('meau.nav10_2')}}</el-dropdown-item>
                  <el-dropdown-item command="/participateDBCAiMining">{{$t('meau.nav10_3')}}</el-dropdown-item>
                  <el-dropdown-item command="/participateAiMining">{{$t('meau.nav10_4')}}</el-dropdown-item>
                  <el-dropdown-item command="/participateGPUMining">{{$t('meau.nav10_5')}}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <img class="icon2" src="../assets/arrow.png" alt="">
          </div>
          <!-- <div class="nav_new">
            <el-dropdown popper-class="demo-dropdown" @command="linkHref2">
              <span class="el-dropdown-link">{{$t('meau.nav3')}}</span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="/deployCloud">{{$t('meau.nav3_1')}}</el-dropdown-item>
                  <el-dropdown-item command="/cloudInternet">{{$t('meau.nav3_2')}}</el-dropdown-item>
                  <el-dropdown-item command="/gameAgents">{{$t('meau.nav3_3')}}</el-dropdown-item>
                  <el-dropdown-item command="/convertGame">{{$t('meau.nav3_4')}}</el-dropdown-item>
                  <el-dropdown-item command="/applyForFunding">{{$t('meau.nav3_5')}}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <img class="icon2" src="../assets/arrow.png" alt="">
          </div> -->
          <div class="nav_new">
            <el-dropdown popper-class="demo-dropdown" @command="linkHref2">
              <span class="el-dropdown-link">{{$t('meau.nav4')}}</span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="/createwallet">{{$t('meau.nav4_1')}}</el-dropdown-item>
                  <el-dropdown-item command="/polkaWallet">{{$t('meau.nav4_2')}}</el-dropdown-item>
                  <!-- <el-dropdown-item command="/ERC20Wallet">{{$t('meau.nav4_3')}}</el-dropdown-item> -->
                  <el-dropdown-item command="/mathWallet">{{$t('meau.nav4_4')}}</el-dropdown-item>
                  <el-dropdown-item command="/ImToken">{{$t('meau.nav4_5')}}</el-dropdown-item>
                  <el-dropdown-item command="/TrustWallet">{{$t('meau.nav4_6')}}</el-dropdown-item>
                  <el-dropdown-item command="/metaMask">{{$t('meau.nav4_7')}}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <img class="icon2" src="../assets/arrow.png" alt="">
          </div>
          <!-- <a class="nav1" href="https://deepbrainchain.github.io/DBC-Wiki/" target="_blank">{{$t('meau.nav5')}}</a> -->
          <a class="nav1" href="https://dbc.subscan.io/" target="_blank">{{$t('meau.nav8')}}</a>
          <div class="nav1" :class="{active: href == '/buyDBC'}" @click="linkHref('/buyDBC')">{{$t('meau.nav6')}}</div>
          <!-- <div class="nav1" :class="{active: href == '/6'}" @click="linkHref('/7')">{{$t('meau.nav7')}}</div> -->
          <div class="nav2">
            <el-dropdown popper-class="demo-dropdown" @command="handleCommand">
              <img class="icon1" src="../assets/country.png" alt="">
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="en">ENGLISH</el-dropdown-item>
                  <el-dropdown-item command="zh">简体中文</el-dropdown-item>
                  <el-dropdown-item command="ko">한국어</el-dropdown-item>
                  <el-dropdown-item command="ja">日本語</el-dropdown-item>
                  <el-dropdown-item command="ru">Русский</el-dropdown-item>
                  <el-dropdown-item command="vn">Việt nam</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import { defineComponent, ref, onMounted ,computed, watch} from "vue";
  import { useI18n } from "vue-i18n";
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'ComponentHeader',
    setup() {
      const { t, locale } = useI18n()
      const router = useRouter()
      const route = useRoute()
      const store = useStore()
      let lan = computed(() => store.state.lan)
      const ishover = ref(false)
      const lanObj = {
        'en': 'ENGLISH',
        'ko': '한국어',
        'zh': '简体中文',
        'ja': '日本語',
        'ru': 'Русский',
        'vn': 'Việt nam'
      }
      const href = ref('/')
      const handleCommand = (command) => {
        locale.value = command
        localStorage.setItem('lan', command)
        store.commit('changeLan', command)
        drawer1.value = false
      }
      const change = (el) => {
        ishover.value = el
      }
      const linkHref = (el) => {
        router.push({path: el})
        href.value = el
        localStorage.setItem('path', el)
        store.commit('changePath', el)
      }
      const linkHref2 = (command) => {
        if (command == '/Whitepaper') {
          if (lan.value == 'zh') {
            window.open('/DeepBrainChainWhitepaper_zh.pdf');
          } else {
            window.open('/DeepBrainChainWhitepaper_en.pdf');
          }
          return
        } else if(command == '/WiKi') {
          window.open('https://deepbrainchain.github.io/DBC-Wiki/');
          return
        }
        router.push({path: command})
        href.value = command
        localStorage.setItem('path', command)
        store.commit('changePath', command)
      }
      const drawer = ref(false)
      const drawer1 = ref(false)
      const drawer2 = ref(false)
      const linkHref1 = () => {
        drawer.value = true
      }
      const openDraw2 = () => {
        drawer.value = false
        drawer2.value = true
      }
      const isPc = () => {
        if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
          return true; // 移动端
        }else{
          return false; // PC端
        }
      }
      const isphone = ref(false)
      onMounted(() => {
        href.value = store.state.path
        isphone.value = isPc()
      });
      watch(lan, (newValue) => {
        
      })
      return {
        lan,
        ishover,
        href,
        isphone,
        handleCommand,
        change,
        linkHref,
        drawer,
        linkHref1,
        linkHref2,
        drawer1,
        drawer2,
        openDraw2
      };
    }
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
  width: 100%;
  font-style: normal;
  // font-family: "Noto Sans SC";
  .meau {
    width: 1200px;
    margin: auto;
    height: 60px;
    background: #03061D;
    .content {
      width: 100%;
      height: 60px;
      margin: auto;
      display: flex;
      font-size: 14px;
      color: #899AA9;
      line-height: 72px;
      align-items: center;
      box-sizing: border-box;
      justify-content: space-between;
      .logo {
        width: 217px;
        height: 28px;
        cursor: pointer;
      }
      .nav {
        display: flex;
        align-items: center;
        .nav1 {
          cursor: pointer;
          display: block;
          color: rgba(255, 255, 255, 0.6);
          margin-right: 40px;
          text-decoration: none;
          &.active {
            color: #FFFFFF;
            font-weight: 600;
          }
          &:hover {
            color: #FFFFFF;
            font-weight: 600;
          }
          .el-dropdown-link {
            font-size: 16px;
            &.active {
              color: #FFFFFF;
            }
          }
        }
        .nav_new {
          cursor: pointer;
          display: flex;
          font-weight: 500;
          margin-right: 50px;
          align-items: center;
          &:hover {
            color: #FFFFFF;
          }
          .el-dropdown {
            font-weight: 500;
            margin: 0 5px;
            font-size: 16px;
            color: rgba(255, 255, 255, 0.6);
            font-family: 'Rubik, sans-serif';
            &:hover {
              // font-weight: 600;
              color: #FFFFFF;
            }
            &.active {
              color: #FFFFFF;
              // font-weight: 600;
            }
          }
          .icon1 {
            width: 20px;
            height: 20px;
          }
          .icon2 {
            width: 10px;
            height: 6px;
          }
        }
        .nav2 {
          cursor: pointer;
          display: flex;
          align-items: center;
          font-weight: 500;
          color: #FFFFFF;
          &:hover {
            color: #FFFFFF;
          }
          .el-dropdown {
            font-weight: 500;
            color: #FFFFFF;
            margin: 0 5px;
            font-family: 'Rubik, sans-serif';
          }
          .icon1 {
            width: 20px;
            height: 20px;
          }
          .icon2 {
            width: 10px;
            height: 6px;
          }
        }
      }
    }
  }
}

.lan_Ru.header {
  .meau {
    .content {
      .nav {
        .nav_new {
          margin-right: 25px;
          .el-dropdown {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.drawerInfo {
  height: initial !important;
  border-radius: 20px 20px 0 0;
  .el-drawer__body {
    padding: 0;
  }
  .meauInfo {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .meaulist {
      width: 100%;
      cursor: pointer;
      padding: 20px 0;
      font-size: 34px;
      font-weight: 400;
      text-align: center;
      text-decoration: none;
      color: rgba(54, 63, 94, 0.6);
      &.active {
        font-weight: 600;
        color: #363F5E;
        background: rgba(54, 63, 94, 0.08);
      }
      &:hover {
        font-weight: 600;
        color: #363F5E;
        background: rgba(54, 63, 94, 0.08);
      }
    }
  }
  .iconInfo {
    width: 100%;
    padding: 50px 20px;
    background: #eee;
    .title {
      font-size: 34px;
      color: #363F5E;
      font-weight: 600;
      line-height: 48px;
      text-align: center;
      margin-bottom: 40px;
    }
    .community {
      width: 100%;
      .follow {
        width: 90%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .list {
          margin: 0 20px 10px;
          cursor: pointer;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          color: rgba(54, 63, 94, 0.6);
          p {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 5px;
            img {
              width: 20px;
              margin: 0 5px;
            }
          }
          span {
            width: 80px;
            height: 80px;
            margin-bottom: 5px;
            display: inline-block;
            
          }
          &:hover {
            color: #363F5E;
            span {
              
            }
          }
        }
      }
    }
  }
}
</style>
