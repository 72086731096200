<template>
  <div class="applyForFunding" :class="{lan_En : lan != 'zh'}">
    <div class="bg1"><img src="../../assets/home/cont4_bg1.jpg" alt=""></div>
    <div class="content1">
      <div class="cont_title gradient_text">{{ $t('applyForFunding.title') }}</div>
      <div class="cont_desc">{{ $t('applyForFunding.text1') }}</div>
      <!-- <div class="cont_btn">{{ $t('applyForFunding.text2') }}</div> -->
      <a target="_blank" class="cont_btn" href="https://t.me/dbccouncil">{{ $t('applyForFunding.text2') }}</a>
    </div>
    <div class="content2">
      <p class="cont2_text1">{{ $t('applyForFunding.cont.title') }}</p>
      <p class="cont2_text2">{{ $t('applyForFunding.cont.text') }}</p>
      <div class="procedure">
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text') }} <a class="procedure_text3" target="_blank" href="https://bit.ly/3FJ4gwo">https://bit.ly/3FJ4gwo</a></p>
      </div>
      <div class="procedure">
        <p class="procedure_text1">{{ $t('applyForFunding.procedure.title1') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text1') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text2') }} <a class="procedure_text3" target="_blank" href="https://shimo.im/docs/R6P99TpPWW8wC3yPopen">https://shimo.im/docs/R6P99TpPWW8wC3yPopen</a></p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text3') }} <a class="procedure_text3" target="_blank" href="">https://shimo.im/docs/lfwA369loYQhDprN/open</a></p>
      </div>
      <div class="procedure">
        <p class="procedure_text1">{{ $t('applyForFunding.procedure.title2') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text4') }}</p>
        <img class="procedure_img" src="../../assets/help/apply_img1.png" alt="">
      </div>

      <div class="procedure">
        <p class="procedure_text1">{{ $t('applyForFunding.procedure.title3') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text3_1') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text3_2') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text3_3') }}</p>
        <img class="procedure_img" v-show="lan == 'zh'" src="../../assets/help/apply_img2.png" alt="">
        <img class="procedure_img" v-show="lan != 'zh'" src="../../assets/help/apply_img2_en.png" alt="">
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text3_4') }}</p>
        <img class="procedure_img" src="../../assets/help/apply_img3.png" alt="">
      </div>
      <div class="procedure">
        <p class="procedure_text1">{{ $t('applyForFunding.procedure.title4') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text4_1') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text4_2') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text4_3') }}</p>
      </div>
      <div class="procedure">
        <p class="procedure_text1">{{ $t('applyForFunding.procedure.title5') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text5_1') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text5_2') }}</p>
      </div>
      <div class="procedure">
        <p class="procedure_text1">{{ $t('applyForFunding.procedure.title6') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text6_1') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text6_2') }}</p>
        <p class="procedure_text2">{{ $t('applyForFunding.procedure.text6_3') }}</p>
      </div>
    </div>
  </div>
</template>


<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'applyForFunding',
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
      };
    }
  })
</script>

<style lang="scss" scoped>
.applyForFunding {
  min-width: 1200px;
  margin: auto;
  font-style: normal;
  // font-family: "Noto Sans SC";
  width: 100%;
  overflow: hidden;
  position: relative;
  .bg1 {
    position: absolute;
    top: -500px;
    margin: auto;
    width: 1920px;
    height: 1690px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 1920px;
      height: 1690px;
    }
  }
  .cont_title {
    font-size: 48px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    margin-bottom: 40px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    max-width: 1000px;
    font-size: 16px;
    color: #899AA9;
    font-weight: 400;
    line-height: 24px; 
    text-align: center;
    letter-spacing: 1px;
    a {
      color: #899AA9;
      text-decoration: none;
    }
  }
  .content1 {
    position: relative;
    width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 100px 0 80px;
    box-sizing: border-box;
    .cont_btn {
      margin: auto;
      color: #FFF;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      margin-top: 40px;
      padding: 10px 60px;
      line-height: normal;
      transition: all .5s;
      text-decoration: none;
      background: #41A4FF;
      &:hover {
        background: #0989FF;
      }
    }
  }
  .content2 {
    position: relative;
    width: 1200px;
    margin: auto;
    padding: 100px 0 160px;
    box-sizing: border-box;
    .cont2_text1 {
      color: #FFF;
      font-size: 48px;
      font-weight: 400;
      text-align: center;
      line-height: normal;
      letter-spacing: 1px;
    }
    .cont2_text2 {
      font-size: 16px;
      color: #899AA9;
      font-weight: 400;
      margin-top: 40px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 1px;
    }
    .procedure {
      margin-top: 40px;
      border: 1px solid rgba(255, 255, 255, .3);
      padding: 24px 32px 32px 32px;
      backdrop-filter: blur(12.5px);
      box-shadow: 0px 4px 49px 0px rgba(0, 7, 72, 0.12);
      background: linear-gradient(155deg, rgba(255, 255, 255, 0.00) -2.13%, rgba(255, 255, 255, 0.15) 136.58%);
      .procedure_text1 {
        color: #38EED6;
        font-size: 24px;
        font-weight: 500;
        line-height: normal;
      }
      .procedure_text2 {
        font-size: 16px;
        color: #899AA9;
        font-weight: 400;
        margin-top: 16px;
        line-height: 24px;
        letter-spacing: 1px;
      }
      .procedure_text3 {
        font-size: 16px;
        color: #41A4FF;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 1px;
        text-decoration: underline;
      }
      .procedure_img {
        width: 100%;
        margin-top: 16px;
      }
    }
  }
}

</style>
