<template>
  <div class="convertGame" :class="{lan_En : lan != 'zh'}">
    <div class="bg1"><img src="../../assets/home/cont4_bg1.jpg" alt=""></div>
    <div class="content1">
      <div class="cont_title gradient_text">{{ $t('convertGame.title') }}</div>
      <div class="cont_desc">{{ $t('convertGame.text') }}</div>
      <!-- <div class="cont_btn">{{ $t('convertGame.text1') }}</div> -->
      <a target="_blank" class="cont_btn" href="https://www.deeplink.cloud/">{{ $t('convertGame.text1') }}</a>
    </div>
    <div class="content2">
      <p class="cont2_text1">{{ $t('convertGame.title') }}</p>
      <div class="step_list">
        <div class="step_li delay100 animateHide" v-animate="{delay: 100, class:'fadeInRight'}">
          <div class="step_top"><p class="step_num">01</p> <div class="step_line"></div></div>
          <div class="step_cont">
            <p class="step_title">{{ $t('convertGame.step.title1') }}</p>
            <p class="step_text">{{ $t('convertGame.step.text1') }}</p>
          </div>
        </div>
        <div class="step_li delay500 animateHide" v-animate="{delay: 500, class:'fadeInRight'}">
          <div class="step_top"><p class="step_num">02</p> <div class="step_line"></div></div>
          <div class="step_cont">
            <p class="step_title">{{ $t('convertGame.step.title2') }}</p>
            <p class="step_text">{{ $t('convertGame.step.text2') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'convertGame',
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
      };
    }
  })
</script>

<style lang="scss" scoped>
.convertGame {
  min-width: 1200px;
  margin: auto;
  font-style: normal;
  // font-family: "Noto Sans SC";
  width: 100%;
  overflow: hidden;
  position: relative;
  .bg1 {
    position: absolute;
    top: -500px;
    margin: auto;
    width: 1920px;
    height: 1690px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 1920px;
      height: 1690px;
    }
  }
  .cont_title {
    font-size: 48px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    margin-bottom: 40px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    max-width: 1000px;
    font-size: 16px;
    color: #899AA9;
    font-weight: 400;
    line-height: 24px; 
    text-align: center;
    letter-spacing: 1px;
  }
  .content1 {
    position: relative;
    width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 100px 0 80px;
    box-sizing: border-box;
    .cont_btn {
      margin: auto;
      color: #FFF;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      margin-top: 40px;
      padding: 10px 60px;
      line-height: normal;
      transition: all .5s;
      text-decoration: none;
      border: 1px solid #FFF;
      &:hover {
        color: #10132F;
        background: #FFF;
        border: 1px solid #FFF;
      }
    }
  }
  .content2 {
    position: relative;
    width: 1200px;
    margin: auto;
    padding: 100px 0 160px;
    box-sizing: border-box;
    .cont2_text1 {
      color: #FFF;
      font-size: 48px;
      font-weight: 400;
      text-align: center;
      line-height: normal;
      letter-spacing: 1px;
    }
    .step_list {
      width: 1200px;
      display: flex;
      margin: 40px auto 0;
      .step_li {
        width: 580px;
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
        .step_top {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .step_num {
            font-size: 40px;
            font-weight: 900;
            line-height: 150%;
            margin-right: 16px;
            color: rgba(255, 255, 255, 0.24);
          }
          .step_line {
            flex: 1;
            height: 2px;
            background: linear-gradient(90deg, #36FFCF -3.33%, #438EFF 100%);
          }
        }
        .step_cont {
          padding-left: 15px;
          box-sizing: border-box;
          .step_title {
            color: #FFF;
            font-size: 18px;
            font-weight: 700;
            line-height: 150%;
          }
          .step_text {
            font-size: 16px;
            color: #899AA9;
            font-weight: 500;
            margin-top: 12px;
            line-height: 150%;
          }
        }
      }
    }
  }
}

</style>
