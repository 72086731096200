<template>
  <div class="ERC20Wallet" :class="{lan_En : lan != 'zh'}">
    <div class="bg1"><img src="../../assets/home/cont4_bg1.jpg" alt=""></div>
    <div class="content1">
      <div class="cont_title gradient_text">{{ $t('ERC20Wallet.title') }}</div>
      <div class="walletList">
        <div class="walletLi">
          <p class="wallet_title">{{ $t('ERC20Wallet.text1') }}</p>
          <p class="wallet_text">
            {{ $t('ERC20Wallet.text4') }} <br> 0x1F735f84b07cc20E9aC471C291a87b5A2428d518
          </p>
          <p class="wallet_text">{{ $t('ERC20Wallet.text5') }}15</p>
        </div>
        <div class="walletLi">
          <p class="wallet_title">{{ $t('ERC20Wallet.text2') }}</p>
          <p class="wallet_text">
            {{ $t('ERC20Wallet.text4') }} <br> 0x1F735f84b07cc20E9aC471C291a87b5A2428d518
          </p>
          <p class="wallet_text">{{ $t('ERC20Wallet.text5') }}15</p>
        </div>
        <div class="walletLi">
          <p class="wallet_title">{{ $t('ERC20Wallet.text3') }}</p>
          <p class="wallet_text">
            {{ $t('ERC20Wallet.text4') }} <br> 0x1F735f84b07cc20E9aC471C291a87b5A2428d518
          </p>
          <p class="wallet_text">{{ $t('ERC20Wallet.text5') }}15</p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'ERC20Wallet',
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
      };
    }
  })
</script>

<style lang="scss" scoped>
.ERC20Wallet {
  min-width: 1200px;
  margin: auto;
  font-style: normal;
  // font-family: "Noto Sans SC";
  width: 100%;
  overflow: hidden;
  position: relative;
  .bg1 {
    position: absolute;
    top: -500px;
    margin: auto;
    width: 1920px;
    height: 1690px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 1920px;
      height: 1690px;
    }
  }
  .cont_title {
    font-size: 48px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    margin-bottom: 40px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    max-width: 1000px;
    font-size: 16px;
    color: #899AA9;
    font-weight: 400;
    line-height: 24px; 
    text-align: center;
    letter-spacing: 1px;
  }
  .content1 {
    position: relative;
    width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 100px 0 160px;
    box-sizing: border-box;
    .cont_btn {
      margin: auto;
      color: #FFF;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      margin-top: 40px;
      padding: 10px 60px;
      line-height: normal;
      transition: all .5s;
      border: 1px solid #FFF;
      &:hover {
        color: #10132F;
        background: #FFF;
        border: 1px solid #FFF;
      }
    }

    .walletList {
      width: 1200px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: auto;
      justify-content: space-between;
      .walletLi {
        width: 384px;
        height: 245px;
        position: relative;
        box-sizing: border-box;
        padding: 48px 24px 32px;
        border: 2px solid #FFF;
        backdrop-filter: blur(12.5px);
        transition: all 1s;
        box-shadow: 0px 4px 49px 0px rgba(0, 7, 72, 0.12);
        background: linear-gradient(155deg, rgba(255, 255, 255, 0.00) -2.13%, rgba(255, 255, 255, 0.15) 136.58%);
        &:hover {
          border: 2px solid ;
          border-image: linear-gradient(to right, #36FFCF, #438EFF) 1;
          backdrop-filter: blur(12.5px);
          box-shadow: 0px 4px 49px 0px rgba(0, 7, 72, 0.12);
          background: linear-gradient(155deg, rgba(255, 255, 255, 0.00) -2.13%, rgba(148, 148, 148, 0.40) 136.58%);
        }
        .wallet_title {
          color: #FFF;
          font-size: 24px;
          font-weight: 500;
          line-height: normal;
        }
        .wallet_text {
          font-size: 16px;
          color: #899AA9;
          font-weight: 400;
          margin-top: 16px;
          line-height: 24px;
          word-break: break-all;
        }
      }
    }
  }
}
</style>
