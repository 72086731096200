<template>
  <div class="aboutTeam" :class="{lan_En : lan != 'zh'}">
    <div class="bg1"><img src="../../assets/home/cont4_bg1.jpg" alt=""></div>
    <div class="bg3"><img src="../../assets/home/cont4_bg3.png" alt=""></div>
    <div class="content1">
      <div class="cont_title gradient_text">{{ $t('aboutTeam.title') }}</div>
      <div class="bossInfo">
        <div class="Infoleft">
          <div class="member_img"><img src="../../assets/boss.jpg" alt="" srcset=""></div>
          <div class="member_name gradient_text">{{ $t('aboutTeam.lead') }}</div>
          <div class="member_job">{{ $t('aboutTeam.job') }}</div>
        </div>
        <div class="Inforight">
          <p class="info_text">{{ $t('aboutTeam.intro.text1') }}</p>
          <p class="info_text">{{ $t('aboutTeam.intro.text2') }}</p>
          <p class="info_text">{{ $t('aboutTeam.intro.text3') }}</p>
          <p class="info_text">{{ $t('aboutTeam.intro.text4') }}</p>
          <p class="info_text">{{ $t('aboutTeam.intro.text5') }}</p>
          <p class="info_text">{{ $t('aboutTeam.intro.text6') }}</p>
        </div>
      </div>
    </div>
    <div class="content2">
      <div class="cont2_text1">{{ $t('aboutTeam.application.title') }}</div>
      <div class="cont2_text2 gradient_text">service@deepbrainchain.org</div>
      <div class="cont2_text3">{{ $t('aboutTeam.application.desc') }}</div>
    </div>
    <div class="content3">
      <div class="cont3_text1">{{ $t('aboutTeam.weekly') }}</div>
      <div class="weeklyList">
        <div class="weeklyli" v-for="(el, index) in weekly_list" :key="index">
          <p v-show="lan == 'zh'" class="weekly_title">第{{el.periods}}期</p>
          <p v-show="lan != 'zh'" class="weekly_title">Issue {{el.periods}}</p>
          <div class="weekly_img"><img :src="el.img_en" alt=""></div>
          <a target="_blank" class="weekly_btn" :href="el.link_en">{{ $t('aboutTeam.see') }}</a>
        </div>
      </div>
      <a target="_blank" class="cont_btn" href="https://deepbrainchainglobal.medium.com/">{{ $t('introDBC.seemore') }}</a>
    </div>
  </div>
</template>


<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'aboutTeam',
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      const weekly_list = ref([
        {
          periods: 158,
          img_zh: 'https://miro.medium.com/v2/resize:fit:720/format:webp/1*KzHzI_J8pM4ExK6E71AwYQ.png',
          link_zh: 'https://deepbrainchainglobal.medium.com/deepbrain-chain-progress-report-158-10-01-10-31-2024-d069189c85e5',
          img_en: 'https://miro.medium.com/v2/resize:fit:720/format:webp/1*KzHzI_J8pM4ExK6E71AwYQ.png',
          link_en: 'https://deepbrainchainglobal.medium.com/deepbrain-chain-progress-report-158-10-01-10-31-2024-d069189c85e5'
        },
        {
          periods: 157,
          img_zh: '',
          link_zh: '',
          img_en: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*GFPRKE09yj_3zDFw',
          link_en: 'https://deepbrainchainglobal.medium.com/deepbrain-chain-progress-report-157-09-01-09-30-2024-8c5017606cb6'
        },
        {
          periods: 156,
          img_zh: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*2bMlGC4HTQVGn9vV',
          link_zh: 'https://deepbrainchainglobal.medium.com/2024%E6%B7%B1%E8%84%91%E9%93%BE%E5%8F%8C%E5%91%A8%E6%8A%A5%E7%AC%AC156%E6%9C%9F-08-01-08-31-ae4381016862',
          img_en: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*9WKZ-DwdPdvG5pPp',
          link_en: 'https://deepbrainchainglobal.medium.com/deepbrain-chain-progress-report-156-08-01-08-31-2024-41e7f246cda2'
        },
        {
          periods: 155,
          img_zh: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*WAiDmLSDjjb6wyd0',
          link_zh: 'https://deepbrainchainglobal.medium.com/2024%E6%B7%B1%E8%84%91%E9%93%BE%E5%8F%8C%E5%91%A8%E6%8A%A5%E7%AC%AC155%E6%9C%9F-07-01-07-31-8506ecaf1a36',
          img_en: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*VA8lYlT4Dkh5QQUH',
          link_en: 'https://deepbrainchainglobal.medium.com/deepbrain-chain-progress-report-155-07-01-07-31-2024-510fa6d800f8'
        },
        {
          periods: 154,
          img_zh: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*-MxrS2bu9LTnwFxh',
          link_zh: 'https://deepbrainchainglobal.medium.com/2024%E6%B7%B1%E8%84%91%E9%93%BE%E5%8F%8C%E5%91%A8%E6%8A%A5%E7%AC%AC154%E6%9C%9F-06-01-06-30-2c9a70c62941',
          img_en: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*-XR1-pxKeY6UIPoJ',
          link_en: 'https://deepbrainchainglobal.medium.com/deepbrain-chain-progress-report-154-06-01-06-30-2024-2b4899ea8833'
        },
        {
          periods: 153,
          img_zh: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*SjQEpj2_rDQc8h7W',
          link_zh: 'https://deepbrainchainglobal.medium.com/2024%E6%B7%B1%E8%84%91%E9%93%BE%E5%8F%8C%E5%91%A8%E6%8A%A5%E7%AC%AC153%E6%9C%9F-05-01-05-31-7a9e1ced2ab2',
          img_en: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*rkEteu91tjUQJhCI',
          link_en: 'https://deepbrainchainglobal.medium.com/deepbrain-chain-progress-report-153-05-01-05-31-2024-8f61b7a12df5'
        },

        {
          periods: 152,
          img_zh: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*6sGsV0K7nJyR8KZw',
          link_zh: 'https://deepbrainchainglobal.medium.com/2024%E6%B7%B1%E8%84%91%E9%93%BE%E5%8F%8C%E5%91%A8%E6%8A%A5%E7%AC%AC152%E6%9C%9F-04-01-04-15-1a606d222e18',
          img_en: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*likVCmL_Hd1qwLX6',
          link_en: 'https://deepbrainchainglobal.medium.com/deepbrain-chain-progress-report-152-04-01-04-15-2024-e9d4c47ab278'
        },
        {
          periods: 151,
          img_zh: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*pM6o3eyRcH6-d82H',
          link_zh: 'https://deepbrainchainglobal.medium.com/2024%E6%B7%B1%E8%84%91%E9%93%BE%E5%8F%8C%E5%91%A8%E6%8A%A5%E7%AC%AC151%E6%9C%9F-03-16-03-31-fd2328c70c72',
          img_en: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*zAedadumYJIUyKol',
          link_en: 'https://deepbrainchainglobal.medium.com/deepbrain-chain-progress-report-151-03-16-03-31-2024-9ff0b7cc6274'
        },
        {
          periods: 150,
          img_zh: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*rfoatFDXrvn7MBI7',
          link_zh: 'https://deepbrainchainglobal.medium.com/2024%E6%B7%B1%E8%84%91%E9%93%BE%E5%8F%8C%E5%91%A8%E6%8A%A5%E7%AC%AC150%E6%9C%9F-03-01-03-15-dbef1e7400ee',
          img_en: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*K7z0og94PqUw3_ur',
          link_en: 'https://deepbrainchainglobal.medium.com/deepbrain-chain-progress-report-150-03-01-03-15-2024-4f2aaada5b46'
        },
        {
          periods: 149,
          img_zh: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*wCCL6TdRojvqHKKU',
          img_en: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*alh95a1X4E4s_VQg',
          link_zh: 'https://deepbrainchainglobal.medium.com/2024%E6%B7%B1%E8%84%91%E9%93%BE%E5%8F%8C%E5%91%A8%E6%8A%A5%E7%AC%AC149%E6%9C%9F-02-01-02-29-8091a55c0633',
          link_en: 'https://deepbrainchainglobal.medium.com/deepbrain-chain-progress-report-149-02-01-02-29-2024-35c972d68e03'
        },
        {
          periods: 148,
          img_zh: 'https://miro.medium.com/v2/resize:fit:828/format:webp/0*RgD24Gy5LwOnih-t',
          link_zh: 'https://deepbrainchainglobal.medium.com/2024%E6%B7%B1%E8%84%91%E9%93%BE%E5%8F%8C%E5%91%A8%E6%8A%A5%E7%AC%AC148%E6%9C%9F-01-16-01-31-ee73c0a2be2b',
          img_en: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*6BiDA4bbq1-q6IUi',
          link_en: 'https://deepbrainchainglobal.medium.com/deepbrain-chain-progress-report-148-01-16-01-31-2024-11a106132286'
        },
        {
          periods: 147,
          img_zh: 'https://miro.medium.com/v2/resize:fit:828/format:webp/0*hROOnZPVa2E5Zwuk',
          link_zh: 'https://deepbrainchainglobal.medium.com/%E7%AC%AC147%E6%9C%9F2023%E6%B7%B1%E8%84%91%E9%93%BE%E5%B9%B4%E6%8A%A5-2023-12-16-2024-01-15-57a7c8a02141',
          img_en: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*6cu9Iu6sheXLrqFH',
          link_en: 'https://deepbrainchainglobal.medium.com/147-dbc-2023-end-of-year-report-2023-12-16-2024-01-15-d31218685a5d'
        },
        {
          periods: 146,
          img_zh: 'https://miro.medium.com/v2/resize:fit:828/format:webp/0*CeAmS5sZyrOaley5',
          link_zh: 'https://deepbrainchainglobal.medium.com/2023%E6%B7%B1%E8%84%91%E9%93%BE%E5%8F%8C%E5%91%A8%E6%8A%A5%E7%AC%AC146%E6%9C%9F-12-01-12-15-067cee2b4ce1',
          img_en: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*2LND1DxGMtvLYWG6',
          link_en: 'https://deepbrainchainglobal.medium.com/deepbrain-chain-progress-report-146-12-01-12-15-2023-8c6891b10548'
        },
        {
          periods: 145,
          img_zh: 'https://miro.medium.com/v2/resize:fit:828/format:webp/0*q-kiMZfWDOhgUJZO',
          link_zh: 'https://deepbrainchainglobal.medium.com/2023%E6%B7%B1%E8%84%91%E9%93%BE%E5%8F%8C%E5%91%A8%E6%8A%A5%E7%AC%AC145%E6%9C%9F-11-16-11-30-82fcbaf27fe9',
          img_en: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*I_yzvZ9sfzndTTTB',
          link_en: 'https://deepbrainchainglobal.medium.com/deepbrain-chain-progress-report-145-11-16-11-30-2023-7421da6f313a'
        },
        {
          periods: 144,
          img_zh: 'https://miro.medium.com/v2/resize:fit:828/format:webp/0*yeDvgDk-tPv_OL0U',
          link_zh: 'https://deepbrainchainglobal.medium.com/2023%E6%B7%B1%E8%84%91%E9%93%BE%E5%8F%8C%E5%91%A8%E6%8A%A5%E7%AC%AC144%E6%9C%9F-11-01-11-15-d3e3d0249c74',
          img_en: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*pGIu0cGyYXEaWwQD',
          link_en: 'https://deepbrainchainglobal.medium.com/deepbrain-chain-progress-report-144-11-01-11-15-2023-1dc18547bad8'
        },
        {
          periods: 143,
          img_zh: 'https://miro.medium.com/v2/resize:fit:828/format:webp/0*DL0sGCEatplTQk2I',
          link_zh: 'https://deepbrainchainglobal.medium.com/2023%E6%B7%B1%E8%84%91%E9%93%BE%E5%8F%8C%E5%91%A8%E6%8A%A5%E7%AC%AC143%E6%9C%9F-10-16-10-31-b23fc9b7b8b7',
          img_en: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*mZWTKtfQV4NaXWYP',
          link_en: 'https://deepbrainchainglobal.medium.com/deepbrain-chain-progress-report-143-10-16-10-31-2023-2b2aae92f4ed'
        },
        // {
        //   periods: 142,
        //   img_zh: 'https://miro.medium.com/v2/resize:fit:828/format:webp/0*C1FFXb5MIkPKiziX',
        //   link_zh: 'https://deepbrainchainglobal.medium.com/2023%E6%B7%B1%E8%84%91%E9%93%BE%E5%8F%8C%E5%91%A8%E6%8A%A5%E7%AC%AC142%E6%9C%9F-09-16-09-30-a7cfd6bc6cba',
        //   img_en: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*cvJnbxXM0gLDBj5y',
        //   link_en: 'https://deepbrainchainglobal.medium.com/deepbrain-chain-progress-report-142-09-16-09-30-2023-1c03261019fb'
        // },
        // {
        //   periods: 141,
        //   img_zh: 'https://miro.medium.com/v2/resize:fit:828/format:webp/0*dUhkVtP1G9NPwAQ-',
        //   link_zh: 'https://deepbrainchainglobal.medium.com/2023%E6%B7%B1%E8%84%91%E9%93%BE%E5%8F%8C%E5%91%A8%E6%8A%A5%E7%AC%AC141%E6%9C%9F-09-01-09-15-7f556173ca51',
        //   img_en: '',
        //   link_en: 'https://deepbrainchainglobal.medium.com/deepbrain-chain-progress-report-141-09-01-09-15-2023-897977d6d9ca'
        // },
        // {
        //   periods: 140,
        //   img_zh: 'https://miro.medium.com/v2/resize:fit:828/format:webp/0*1spTk0SSzkpvtwlJ',
        //   link_zh: 'https://deepbrainchainglobal.medium.com/2023%E6%B7%B1%E8%84%91%E9%93%BE%E5%8F%8C%E5%91%A8%E6%8A%A5%E7%AC%AC140%E6%9C%9F-08-16-08-31-b7b4f5afbc3a',
        //   img_en: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*wNqleS9ufqplQjpa',
        //   link_en: 'https://deepbrainchainglobal.medium.com/deepbrain-chain-progress-report-140-08-16-08-31-2023-87df2886bf5d'
        // },
        // {
        //   periods: 139,
        //   img_zh: 'https://miro.medium.com/v2/resize:fit:828/format:webp/0*bXFxFldgkSSKVOCc',
        //   link_zh: 'https://deepbrainchainglobal.medium.com/2023%E6%B7%B1%E8%84%91%E9%93%BE%E5%8F%8C%E5%91%A8%E6%8A%A5%E7%AC%AC139%E6%9C%9F-08-01-08-15-a4d053d34d48',
        //   img_en: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*H_1l0DSY0GKEmUiF',
        //   link_en: 'https://deepbrainchainglobal.medium.com/deepbrain-chain-progress-report-139-08-01-08-15-2023-ed1c0ddb638'
        // },
        // {
        //   periods: 138,
        //   img_zh: 'https://miro.medium.com/v2/resize:fit:828/format:webp/0*khSWFnCWyMmkz3Vl',
        //   link_zh: 'https://deepbrainchainglobal.medium.com/2023%E6%B7%B1%E8%84%91%E9%93%BE%E5%8F%8C%E5%91%A8%E6%8A%A5%E7%AC%AC138%E6%9C%9F-07-16-07-31-3ccfdc544a80',
        //   img_en: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*TxgPvoJlbzCFiEmn',
        //   link_en: 'https://deepbrainchainglobal.medium.com/deepbrain-chain-progress-report-138-07-16-07-31-2023-14637d164982'
        // },
        // {
        //   periods: 137,
        //   img_zh: 'https://miro.medium.com/v2/resize:fit:828/format:webp/0*9HXvSAmQTRHYUlk_',
        //   link_zh: 'https://deepbrainchainglobal.medium.com/2023%E6%B7%B1%E8%84%91%E9%93%BE%E5%8F%8C%E5%91%A8%E6%8A%A5%E7%AC%AC137%E6%9C%9F-07-01-07-15-6b1042e2bbfe',
        //   img_en: 'https://miro.medium.com/v2/resize:fit:720/format:webp/0*pawqRaTb2WjBk3BJ',
        //   link_en: 'https://deepbrainchainglobal.medium.com/deepbrain-chain-progress-report-137-07-01-07-15-2023-26d605223f2d'
        // }
      ])
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
        weekly_list
      };
    }
  })
</script>

<style lang="scss" scoped>
.aboutTeam {
  min-width: 1200px;
  margin: auto;
  font-style: normal;
  // font-family: "Noto Sans SC";
  width: 100%;
  overflow: hidden;
  position: relative;
  .bg1 {
    position: absolute;
    top: -500px;
    margin: auto;
    width: 1920px;
    height: 1690px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 1920px;
      height: 1690px;
    }
  }
  .bg3 {
    position: absolute;
    top: 150px;
    margin: auto;
    width: 1603px;
    height: 1348px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 1603px;
      height: 1348px;
    }
  }
  .cont_title {
    font-size: 48px;
    font-weight: 500;
    text-align: center;
    line-height: normal;
    margin-bottom: 40px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    max-width: 1200px;
    font-size: 16px;
    color: #899AA9;
    font-weight: 400;
    line-height: 24px; 
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 40px;
  }
  .content1 {
    position: relative;
    width: 1200px;
    margin: auto;
    padding: 100px 0 80px;
    box-sizing: border-box;
    .bossInfo {
      display: flex;
      justify-content: space-between;
      .Infoleft {
        width: 320px;
        display: flex;
        align-items: center;
        flex-direction: column;
        .member_img {
          width: 160px;
          height: 160px;
          overflow: hidden;
          display: flex;
          align-items: center;
          margin-bottom: 24px;
          border-radius: 160px;
          justify-content: center;
          background: url(../../assets/learn/use_bg.png) 100%/100% no-repeat;
          img {
            width: 152px;
            height: 152px;
            border-radius: 152px;
          }
        }
        .member_name {
          font-size: 24px;
          font-weight: 400;
          line-height: 24px;
        }
        .member_job {
          margin: 8px 0;
          font-size: 16px;
          color: #899AA9;
          font-weight: 400;
          line-height: 24px;
        }
      }
      .Inforight {
        flex: 1;
        .info_text {
          font-size: 16px;
          font-weight: 400;
          color: #899AA9;
          line-height: 24px;
          margin-bottom: 24px;
          letter-spacing: 1px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .content2 {
    position: relative;
    width: 1200px;
    margin: auto;
    display: flex;
    padding: 80px 0;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: space-between;
    .cont2_text1 {
      color: #fff;
      font-size: 24px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 1px;
    }
    .cont2_text2 {
      color: #fff;
      font-size: 40px;
      font-weight: 400;
      margin: 24px auto;
      letter-spacing: 1px;
    }
    .cont2_text3 {
      width: 900px;
      font-size: 16px;
      font-weight: 400;
      color: #899AA9;
      line-height: 24px;
      text-align: center;
      letter-spacing: 1px;
    }
  }
  .content3 {
    position: relative;
    width: 1200px;
    margin: auto;
    display: flex;
    padding: 80px 0;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: space-between;
    .cont3_text1 {
      width: 100%;
      color: #fff;
      font-size: 48px;
      font-weight: 400;
      text-align: center;
      line-height: normal;
      letter-spacing: 1px;
    }
    .weeklyList {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .weeklyli {
        width: 282px;
        padding: 24px;
        display: flex;
        margin-top: 40px;
        box-sizing: border-box;
        align-items: center;
        flex-direction: column;
        border: 2px solid rgba(65, 164, 255, 0.16);
        backdrop-filter: blur(8px);
        background: rgba(65, 164, 255, 0.16);
        transition: all 0.3s;
        &:hover {
          border: 2px solid;
          border-image: linear-gradient(to right, #36FFCF, #438EFF) 1;
          .weekly_btn {
            background: linear-gradient(90deg, #36FFCF -3.33%, #438EFF 100%);
          }
        }
        .weekly_title {
          color: #899AA9;
          font-size: 14px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.28px;
        }
        .weekly_img {
          width: 100%;
          height: 160px;
          margin: 12px 0;
          overflow: hidden;
          img {
            width: 100%;
            height: 160px;
            object-fit: cover;
          }
        }
        .weekly_text {
          font-size: 16px;
          color: #41A4FF;
          font-weight: 400;
          margin-bottom: 8px;
          line-height: normal;
          letter-spacing: 0.32px;
          span {
            margin-right: 16px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        .weekly_btn {
          width: 100%;
          height: 48px;
          color: #fff;
          cursor: pointer;
          font-size: 16px;
          font-weight: 500;
          align-items: center;
          display: inline-flex;
          text-decoration: none;
          justify-content: center;
          border: 1px solid #FFF;
        }
      }
    }
    .cont_btn {
      margin: auto;
      color: #FFF;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      margin-top: 40px;
      padding: 10px 60px;
      line-height: normal;
      transition: all .5s;
      text-decoration: none;
      border: 1px solid #FFF;
      &:hover {
        color: #10132F;
        background: #FFF;
        border: 1px solid #FFF;
      }
    }
  }
}

</style>
