<template>
  <div class="paymentRelated" :class="{lan_En : lan != 'zh'}">
    <div class="pagecont1">
      <div class="title">{{ $t('paymentRelated.title') }}</div>
      <div class="bg1"><img src="../../assets/aipro/aibg2.png" alt=""></div>
      <div class="pagecont">
        <div class="cont_left">
          <div class="list">
            <div class="listInfo delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">
              <div class="list_num">1</div>
              <div class="list_cont">
                <div class="cont_desc">{{ $t('paymentRelated.text1') }}</div>
              </div>
            </div>
            <div class="listInfo delay300 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">
              <div class="list_num">2</div>
              <div class="list_cont">
                <div class="cont_desc">{{ $t('paymentRelated.text2') }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="cont_right delay100 animateHide" v-animate="{delay: 100, class:'amplify'}">
          <div class="bg">
            <img src="../../assets/aipro/aibg5.png" alt="">
          </div>
          <img class="contimg" src="../../assets/aipro/ai_img5.png" alt="">
          <div class="text text1">{{ $t('paymentRelated.text3') }}</div>
          <div class="text text2">{{ $t('paymentRelated.text4') }}</div>
          <div class="text text3">{{ $t('paymentRelated.text5') }}</div>
          <div class="text text4">{{ $t('paymentRelated.text6') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'paymentRelated',
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
      };
    }
  })
</script>

<style lang="scss" scoped>
.paymentRelated {
  margin: auto;
  font-style: normal;
  width: 100%;
  overflow: hidden;
  position: relative;
  .cont_title {
    color: #fff;
    font-size: 32px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    font-size: 16px;
    line-height: 24px; 
    letter-spacing: 1px;
    color: rgba(255, 255, 255, .6);
  }
  .pagecont1 {
    position: relative;
    width: 100%;
    margin: auto;
    max-width: 1200px;
    padding: 80px 0 100px;
    box-sizing: border-box;    
    .bg1 {
      position: absolute;
      top: -200px;
      left: -100px;
      margin: auto;
      width: 700px;
      height: 700px;
      img {
        width: 700px;
        height: 700px;
      }
    }
    .title {
      font-size: 48px;
      font-weight: 600;
      line-height: auto;
      color: transparent;
      text-align: center;
      margin: 0 auto 100px;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(90deg, #03FFF0 30%, #6409F7 70%);
    }
    .pagecont {
      display: flex;
      text-align: left;
      justify-content: space-between;
      .cont_left {
        width: 540px;
        display: flex;
        flex-direction: column;
        .list {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .listInfo {
            position: relative;
            width: 100%;
            padding: 12px 18px;
            margin-bottom: 40px;
            box-sizing: border-box;
            .list_num {
              position: absolute;
              top: 0;
              left: 0;
              color: #fff;
              width: 52px;
              height: 44px;
              font-size: 24px;
              font-weight: 600;
              line-height: 44px;
              text-align: center;
              border-radius: 10px;
              background: linear-gradient(315deg, #03FFF0 0%, #6409F7 100%);
            }
            .list_cont {
              padding: 30px;
              border-radius: 20px;
              box-sizing: border-box;
              background: rgba(255, 255, 255, .05);
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
          .mb20 {
            margin-bottom: 20px;
          }
          .ml20 {
            margin-left: 20px;
          }
          a {
            color: rgba(255, 255, 255, .6);
          }
        }
      }
      .cont_right {
        position: relative;
        width: 622px;
        display: flex;
        flex-direction: column;
        .bg {
          position: absolute;
          right: -220px;
          top: -180px;
          width: 1000px;
          img {
            width: 1000px;
          }
        }
        .contimg {
          position: relative;
          z-index: 2;
          width: 622px;
          img {
            width: 622px;
          }
        }
        .text {
          color: #fff;
          display: flex;
          font-size: 16px;
          line-height: 24px;
          padding: 5px 15px;
          align-items: center;
          box-sizing: border-box;
          justify-content: center;
        }
        .text1{ 
          position: absolute;
          width: 190px;
          height: 68px;
          top: 245px;
          left: 66px;
        }
        .text2 {
          position: absolute;
          width: 280px;
          top: 325px;
          left: 20px;
          font-size: 18px;
          font-weight: 600;
        }
        .text3 {
          position: absolute;
          right: 0;
          top: 102px;
          width: 190px;
          height: 68px;
        }
        .text4 {
          position: absolute;
          right: 40px;
          top: 244px;
          width: 200px;
        }
      }
    }
    
  }
}
</style>
