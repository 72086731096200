<template>
  <div class="whatisAi" :class="{lan_En : lan != 'zh'}">
    <div class="pagecont1">
      <div class="bg1"><img src="../../assets/aipro/aibg2.png" alt=""></div>
      <div class="cont_left">
        <img src="../../assets/aipro/ai_img1.png" alt="">
      </div>
      <div class="cont_right">
        <div class="list">
          <div class="listInfo delay100 animateHide" v-animate="{delay: 100, class:'fadeInUp'}">
            <div class="text1 cont_title">{{ $t('whatisAi.title1') }}</div>
            <div class="text2 cont_desc">{{ $t('whatisAi.text1') }}</div>
          </div>
          <div class="listInfo delay200 animateHide" v-animate="{delay: 200, class:'fadeInUp'}">
            <div class="text1 cont_title">{{ $t('whatisAi.title2') }}</div>
            <div class="text2 cont_desc">{{ $t('whatisAi.text2') }}</div>
          </div>
          <div class="listInfo delay300 animateHide" v-animate="{delay: 300, class:'fadeInUp'}">
            <div class="text1 cont_title">{{ $t('whatisAi.title3') }}</div>
            <div class="text2 cont_desc">{{ $t('whatisAi.text3') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagecont2">
      <div class="bg">
        <img src="../../assets/aipro/aibg2.png" alt="">
      </div>
      <div class="cont_left animateHide" v-animate="{delay: 0, class:'fadeInLeft'}">
        <div class="title cont_title">{{ $t('whatisAi.title4') }}</div>
        <div class="title1">{{ $t('whatisAi.text4_1') }}</div>
        <div class="text">
          <span></span>
          <p class="cont_desc">{{ $t('whatisAi.text4_2') }}</p>
        </div>
        <div class="text">
          <span></span>
          <p class="cont_desc">{{ $t('whatisAi.text4_3') }}</p>
        </div>
      </div>
      <div class="cont_right animateHide" v-animate="{delay: 0, class:'fadeInRight'}">
        <img src="../../assets/aipro/ai_img2.png" alt="">
      </div>
    </div>
    <div class="pagecont3 animateHide" v-animate="{delay: 0, class:'fadeInDown'}">
      <div class="bg">
        <img src="../../assets/aipro/aibg3.png" alt="">
      </div>
      <div class="cont_right">
        <img src="../../assets/aipro/ai_img3.png" alt="">
      </div>
      <div class="cont_left">
        <div class="title cont_title">{{ $t('whatisAi.title5') }}</div>
        <div class="text">
          <p class="cont_desc">{{ $t('whatisAi.text5_1') }}</p>
          <p class="cont_desc">{{ $t('whatisAi.text5_2') }}</p>
          <p class="cont_desc">{{ $t('whatisAi.text5_3') }}</p>
          <p class="cont_desc">{{ $t('whatisAi.text5_4') }}</p>
          <p class="cont_desc">{{ $t('whatisAi.text5_5') }}</p>
          <p class="cont_desc">{{ $t('whatisAi.text5_6') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import { defineComponent, ref, computed, watch, onMounted  } from "vue";
  import { useStore } from "vuex"
  export default defineComponent({
    name: 'whatisAi',
    setup() {
      const store = useStore()
      let lan = computed(() =>  store.state.lan)
      watch(lan, (newvlaue, oldvalue) => {
        console.log(newvlaue, oldvalue, 'ssss');
      })
      onMounted(() => {

      });
      return {
        lan,
      };
    }
  })
</script>

<style lang="scss" scoped>
.whatisAi {
  margin: auto;
  font-style: normal;
  width: 100%;
  overflow: hidden;
  position: relative;
  .cont_title {
    color: #fff;
    font-size: 32px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
    letter-spacing: 1.6px;
  }
  .cont_desc {
    font-size: 16px;
    line-height: 24px; 
    letter-spacing: 1px;
    color: rgba(255, 255, 255, .8)
  }
  .pagecont1 {
    position: relative;
    width: 100%;
    margin: auto;
    display: flex;
    max-width: 1200px;
    align-items: center;
    padding: 80px 0 100px;
    box-sizing: border-box;    
    justify-content: space-between;
    .bg1 {
      position: absolute;
      top: -200px;
      left: -100px;
      margin: auto;
      width: 700px;
      height: 700px;
      img {
        width: 700px;
        height: 700px;
      }
    }
    .cont_left {
      width: 500px;
      img {
        width: 500px;
      }
    }
    .cont_right {
      width: 600px;
      display: flex;
      padding-left: 40px;
      .list {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .listInfo {
          width: 100%;
          padding-left: 40px;
          margin-bottom: 40px;
          box-sizing: border-box;
          backdrop-filter: blur(2px);
          border-left: 1px solid #0EF0FF;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .pagecont2 {
    width: 100%;
    display: flex;
    max-width: 1200px;
    position: relative;
    align-items: center;
    margin: 0 auto 100px;
    box-sizing: border-box;    
    justify-content: space-between;
    .bg {
      position: absolute;
      right: -200px;
      top: -100px;
      width: 700px;
      img {
        width: 700px;
      }
    }
    .cont_left {
      width: 700px;
      display: flex;
      flex-direction: column;
      .title {
        width: 100%;
      }
      .title1 {
        color: #fff;
        font-size: 20px;
        line-height: normal;
        margin-bottom: 20px;
        letter-spacing: 1.6px;
      }
      .text {
        display: flex;
        margin-bottom: 20px;
        span {
          width: 1px;
          height: 20px;
          margin-top: 5px;
          margin-right: 20px;
          border-radius: 1px;
          border: 1px solid #0EF0FF;
        }
      }
    }
    .cont_right {
      width: 430px;
      img {
        width: 430px;
      }
    }
  }
  .pagecont3 {
    width: 100%;
    display: flex;
    max-width: 1200px;
    position: relative;
    align-items: center;
    margin: 0 auto 100px;
    box-sizing: border-box;    
    justify-content: space-between;
    .bg {
      position: absolute;
      left: -200px;
      top: -100px;
      width: 700px;
      img {
        width: 700px;
      }
    }
    .cont_left {
      width: 640px;
      display: flex;
      flex-direction: column;
      .title {
        width: 100%;
      }
      .text {
        padding-left: 20px;
        border-left: 4px solid #0EF0FF;
      }
    }
    .cont_right {
      width: 470px;
      img {
        width: 470px;
      }
    }
  }
}
</style>
