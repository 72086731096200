import axios from "@/utils/axios"

// 获取DBC价格
export const dbcPriceOcw = () => {
  return axios({
    method: "get",
    url: 'https://dbchaininfo.congtu.cloud/query/dbc_info?language=CN' 
  });
};

// 获取DBC价格
export const getHodoler = (data) => {
  return axios({
    method: "post",
    url: 'https://dbc.api.subscan.io/api/v2/scan/daily',
    data: data
  });
};